import React, {Dispatch, useState} from 'react';

import {Button} from 'react-bootstrap';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Input,
    TextField
} from "@material-ui/core";
import {Transaction} from "../../reducers/transactionReducer";
import NickHeadshot from "../../static/images/NickHeadshot.jpg";
import {Bank, User} from "../../reducers/userReducer";
import BankImage from "../../static/images/bank.jpeg"
var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
});
function TransactionModal(props: {isShown: boolean, closefunc: any, transaction: Transaction, user: User | null, isToMe: boolean}) {
    const isBank:boolean = props.transaction.fromBank != null || props.transaction.toBank != null
    const bank: Bank = props.transaction.fromBank == null ?  props.transaction.toBank : props.transaction.fromBank


    return (
            <div style={{backgroundColor: "rgba(0,0,0,.5)", position: "fixed", left: 0, top: 0, height: "100vh", width: "100%",alignItems: "center", display: props.isShown ? "flex" : "none", zIndex: 420, justifyContent: "center"}}>
                <div style={{zIndex: 421, width: "50vh", height: "max-content", backgroundColor: "white", borderRadius: 10,  padding: 10 }}>
                        <div style={{backgroundColor: '#eee', width: "50vh", minHeight: 100, borderTopRightRadius: 20, borderTopLeftRadius: 20, marginTop: -10, marginLeft: -10, padding: 10}}>
                            <img style={{backgroundImage: "black", borderRadius: "100%", height: 125, width: 125, objectFit: "cover"}}  src={isBank ? BankImage : process.env.REACT_APP_API_URL+`/api/Account/${props.user!?.accountID}/profilepic`}/>

                            {!isBank && <h3 className={"font-weight-light"}>{props.isToMe ? `${props.user?.firstName} sent you ${formatter.format(props.transaction.amount)}` : `Sent ${formatter.format(props.transaction.amount)} to ${props.user?.firstName}`}</h3>}
                            {isBank && <h3 className={"font-weight-light"}>{props.isToMe ? `Added ${formatter.format(props.transaction.amount)} to your CavCash account` : `Withdrew ${formatter.format(props.transaction.amount)} to ${bank.institution} ${bank.type}`}</h3>}

                        </div>
                    <div className={"p-2"}>
                        <div className={"row"}>
                            <p className={"col-4 text-left"}>Date</p>
                            <p className={"col-8 text-right font-weight-normal"}>{props.transaction.dateOccurred}</p>
                        </div>
                    </div>
                    <div className={"p-2"}>
                        <div className={"row"}>
                            <p className={"col-4 text-left"}>Description</p>
                            <p className={"col-8 text-right font-weight-normal"}>{props.transaction.description}</p>
                        </div>
                    </div>
                    <div className={"p-2"}>
                        <div className={"row"}>
                            <p className={"col-4 text-left"}>Transaction Id</p>
                            <p className={"col-8 text-right font-weight-normal"}>{props.transaction.transactionID}</p>
                        </div>
                    </div>
                    <div className={"p-2"}>
                        <div className={"row"}>
                            <p className={"col-4 text-left"}>Status</p>
                            <p className={"col-8 text-right font-weight-normal"}>{props.transaction.isPending ? "Pending" : "Completed"}</p>
                        </div>
                    </div>
                        <a onClick={(e) => {props.closefunc()}} className={"cav-button"}>
                            Close
                        </a>
                </div>
            </div>
        );
}



export default TransactionModal;
