import React, {useEffect, useState} from 'react';
import {TextField} from "@material-ui/core";
import {getUser} from "../../reducers/userReducer";
import {getNextFiveTransactions} from "../../reducers/transactionReducer";

import {useDispatch, useSelector} from "react-redux";
import {makeTransaction, NewTransaction, NewTransactionState} from "../../reducers/sendMoneyReducer";
import {SessionState} from "../../reducers/sessionReducer";
function CreateTransactionModal(props: {isShown: boolean, closefunc: any, prefillId?: string | null}) {
    const dispatch = useDispatch()
    const [userId, setUserId] = useState('');
    const [userPass, setUserPass] = useState('');
    const [amount, setAmount] = useState('');
    const [description, setDescription] = useState('');
    const status: string = useSelector<any, string>((state) => state.sendMoney.status)

useEffect(() => {
    switch(status) {
        case "success":
            dispatch({type: "CLEAR_TRANSACTION_STATUS"})
                // show happy msg
            break;
        case "fail":
            //show sad msg
            break;
        case "none":
            props.closefunc()
            break;
    }
}, [status])

    useEffect(() => {
        if(props.prefillId != null)
            setUserId(props.prefillId)
    }, [])


    function sendMoney() {
        var tr: NewTransaction = {
            toID: userId,
            amount: +amount,
            description: description
        };
        dispatch({type: "SET_TRANSACTION", payload: {transaction: tr, password: userPass}})
        dispatch(makeTransaction())
    }


        return (
            <div style={{backgroundColor: "rgba(0,0,0,.5)", position: "fixed", left: 0, top: 0, height: "100vh", width: "100%",alignItems: "center", display: props.isShown ? "flex" : "none", zIndex: 420, justifyContent: "center"}}>
                <div style={{zIndex: 421, width: "50vh", height: "max-content", backgroundColor: "white", borderRadius: 20,  padding: 10 }}>
                    <h2>Send Money</h2>
                    <div className={"row justify-content-center"}>
                        <TextField  style={{width: "80%", fontSize: "2rem", marginBottom: 20}} variant="outlined" value={userId} required={true} label={"E-Mail/Username"}
                                    full-width onChange={(e)=>{setUserId(e.target.value)}}
                        />
                        <TextField  style={{width: "80%", fontSize: "2rem", marginBottom: 20}} variant="outlined" required={true} label={"Amount"} type={"number"}
                                    full-width onChange={(e)=>{setAmount(e.target.value)}}
                        />
                        <TextField  style={{width: "80%", fontSize: "2rem", marginBottom: 20}} multiline={true} variant="outlined" required={true} label={"Description"}
                                    full-width onChange={(e)=>{setDescription(e.target.value)}}
                        />
                        <TextField  style={{width: "80%", fontSize: "2rem", marginBottom: 20}} variant="outlined" required={true} type="password" label={"Password"}
                                    full-width onChange={(e)=>{setUserPass(e.target.value)}}
                        /><p>We require your password as an added layer of security</p>
                        <a  onClick={(e) => {sendMoney()}} style={{color: "lightgreen"}} className={" m-2 cav-button col-10"}>
                            Send Money
                        </a>
                        <a  onClick={(e) => {props.closefunc()}} className={"m-2 cav-button col-10"}>
                            Close
                        </a>
                    </div>


                </div>

            </div>
        );
}



export default CreateTransactionModal;
