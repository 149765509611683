export interface Transaction {
    transactionID: string,
    fromID: string,
    toID: string,
    amount: number,
    feeAmount: number,
    donation: any,
    description: string,
    dateOccurred: string
    deviceUsedID: string
    processingTerminalID: string,
    locationOccurred: string,
    chargedBack: boolean,
    reportedFraudulent: boolean,
    declined: boolean,
    isPending: boolean,
    toBank: any,
    fromBank: any
}

export interface TransactionState {
    transactions: Transaction[]
}

const initialState : TransactionState = {
    transactions: []
}

type Action = {type: "SET_TRANSACTIONS" | "CLEAR_ON_LOGOUT", payload: {transactions: Transaction[]}}

export const transactionReducer = (state:TransactionState = initialState, action: Action) => {
    switch(action.type) {

        case "SET_TRANSACTIONS":
            return {...state, transactions: action.payload.transactions}

        case "CLEAR_ON_LOGOUT":
            return {}

        default:
            return {...state}
    }
}

export const getNextFiveTransactions = () => async(dispatch: any, getState: any) => {
    var state = getState().session;
// process.env.REACT_APP_API_URL+'/api/Account/transactions/'+state.accountId+'/'+state.userKey.id+"/"+5+(transactionState.transactions.length > 0 ? "/"+transactionState.transactions[transactionState.transactions.length -1].transactionID : "")
    await fetch(process.env.REACT_APP_API_URL+'/api/Account/transactions/'+state.accountId+'/'+state.userKey.id+"/"+5, {
        method: 'GET',
        headers: {
            Authorization: 'MalibuBeach',
            'Content-type': 'application/json'
        }
    }).then(async (response) => {
        if(response.status!==200 && response.status!==201) {
            dispatch({type: "SHOW_MODAL", payload: {message: "Transaction failure.\n"+(await response.json())}})
            dispatch({type: "FAIL_AUTH"})
            dispatch({type: "CLEAR_ON_LOGOUT"})
        }
        else {
            dispatch({type: "SET_TRANSACTIONS", payload: {transactions: await response.json()}})
        }
    });
}

