import React, {useEffect, useState} from 'react';
import {getUser, User, UserState} from "../../reducers/userReducer";
import {useDispatch, useSelector} from "react-redux";
import {SessionState} from "../../reducers/sessionReducer";
import {TextField} from "@material-ui/core";
import {News} from "../../reducers/newsroomReducer";


function PostBlog() {

    const user: User | null = useSelector<any, UserState["user"]>((state) => state.user.user);
    const isLoggedIn: boolean = useSelector<any, SessionState["isLoggedIn"]>((state) => state.session.isLoggedIn)
    const sessionState: SessionState = useSelector<any, SessionState>((state) => state.session);

    const [imageReady,setImageReady] = useState<any>(false);
    const [image,setImage] = useState<any>(null);

    const [title,setTitle] = useState("");
    const [id,setId] = useState("");
    const [content,setContent] = useState("");
    const hiddenFileInput = React.useRef(null);

    const dispatch = useDispatch()
    useEffect(() => {
        if(!isLoggedIn)
            window.location.href = "/login"
        dispatch(getUser())
    }, [isLoggedIn])

    useEffect(() => {
        if(user === null)
            return;

        if(user.accountID != "nickorlow")
            window.location.href="/404"
        setId(user.accountID)
    }, [user])

    useEffect(() => {
        if(!imageReady) {
            setImageReady(true)
            return;
        }
        convertBase64andUpload()
    }, [image])


    const convertBase64andUpload = () => {
        if(image == null)
            return;

        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(image)



            fileReader.onload = () => {

                if(fileReader.result === null || user === null || sessionState === null)
                    return;
                var newsItem : News = {
                    author: user?.accountID || "nickorlow",
                    title: title,
                    content: content,
                    coverImage: fileReader.result.toString(),
                    blogID: '0',
                    createdDate: new Date().toUTCString()
                }
                resolve(fileReader.result);
                fetch(process.env.REACT_APP_API_URL+`/api/blog/${user.accountID}/${sessionState.userKey?.id}/post`, {
                    method: 'POST',
                    body: JSON.stringify(newsItem),
                    headers: {
                        Authorization: 'MalibuBeach',
                        'Content-type': 'application/json'
                    }
                }).then(async (response) => {
                    if(response.status !== 200 && response.status !== 201)
                        dispatch({type: "SHOW_MODAL", payload: {message: "There was an error posting the blog"}})
                    else
                        dispatch({type: "SHOW_MODAL", payload: {message: "Blog Posted"}})
                });
            }
            fileReader.onerror = (error) => {
                dispatch({type: "SHOW_MODAL", payload: {message: "There was an error posting the blog"}})
            }
        })
    }

    return (
      <div style={{padding: "50px", textAlign: 'center'}}>

            <h2>Post a New Blog</h2>
          <div className={"row justify-content-center"}>
              <TextField className={"col-4"}  style={{width: "100%", fontSize: "2rem", marginBottom: 20}} variant="outlined" required={true} label={"Blog Name"}
                          full-width onChange={(e) => {setTitle(e.target.value)}} value={title}
              />

              <div className={"col-1"}/>
              <TextField className={"col-4"} style={{width: "100%", fontSize: "2rem", marginBottom: 20}} variant="outlined" required={true} label={"Poster Id"}
                          full-width onChange={(e) => {setId(e.target.value)}} value={id}
              />
          </div>
          <TextField style={{width: "77%", fontSize: "2rem", marginBottom: 20}} variant="outlined" required={true} label={"Content"} value={content}
                     full-width onChange={(e) => {setContent(e.target.value)}} multiline={true}
          />
            <button className={"cav-button w-100"} onClick={async (e) => {
                if (hiddenFileInput != null && hiddenFileInput.current != null) { // @ts-ignore
                    hiddenFileInput.current.click();
                }
            }}>Post Blog</button>
          <input ref={hiddenFileInput} hidden type="file" onChange={(e)=>{if(e.target.files != null) setImage(e.target.files[0])}} />
      </div>
  );
}
export default PostBlog;
