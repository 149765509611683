import React, {Dispatch, useEffect, useState} from 'react';

import {Button} from 'react-bootstrap';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Input,
    TextField
} from "@material-ui/core";
import {Transaction} from "../../reducers/transactionReducer";
import NickHeadshot from "../../static/images/NickHeadshot.jpg";
import {Bank, User} from "../../reducers/userReducer";
import {useDispatch, useSelector} from "react-redux";
import {SessionState} from "../../reducers/sessionReducer";
import {makeTransaction, NewTransaction} from "../../reducers/sendMoneyReducer";
import {makeBankTransaction, NewBankTransaction} from "../../reducers/bankReducer";
import Arrow from "../../static/images/icons/arrow.png"
var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
});

function BankTransferModal(props: {isShown: boolean, closefunc: any, addingMoney: boolean}) {
    const bank: Bank = useSelector<any, Bank>((state) => state.user.user!?.linkedBank)
const [amount, setAmount] = useState('');
    const [userPass, setUserPass] = useState('');
    const status: string = useSelector<any, string>((state) => state.bankTransfer.status)

    useEffect(() => {
        switch(status) {
            case "success":
                dispatch({type: "CLEAR_BANK_TRANSACTION_STATUS"})
                // show happy msg
                break;
            case "fail":
                //show sad msg
                break;
            case "none":
                props.closefunc()
                break;
        }
    }, [status])
    const dispatch = useDispatch()
    function sendMoney() {
        var tr: NewBankTransaction = {
            toCavCash: props.addingMoney,
            amount: +amount
        };
        dispatch({type: "SET_BANK_TRANSACTION", payload: {transaction: tr, password: userPass}})
        dispatch(makeBankTransaction())
    }


    return (
            <div style={{backgroundColor: "rgba(0,0,0,.5)", position: "fixed", left: 0, top: 0, height: "100vh", width: "100%",alignItems: "center", display: props.isShown ? "flex" : "none", zIndex: 420, justifyContent: "center"}}>
                <div style={{zIndex: 421, width: "50vh", height: "max-content", backgroundColor: "white", borderRadius: 20,  padding: 10 }}>
<h1>{props.addingMoney ? "Adding Money" : "Withdraw"}</h1>
                    <div className={"row justify-content-center"}>
                    <div className={"col-4"}>
                        <h4 className={"m-0"}>{bank?.institution}</h4>
                        <p className={"m-0"}>{bank?.type}</p>
                    </div>
                    <p className={"col-2"}>
                        <img src={Arrow} height={50} style={{transform: props.addingMoney ? "" : "rotate(180deg)"}}/>
                    </p>
                    <div className={"col-4"}>
                        <h4>Your CavCash Account</h4>
                    </div>
                </div>
                    <TextField  style={{width: "80%", fontSize: "2rem", marginBottom: 20}} variant="outlined" required={true} label={"Amount"} type={"number"}
                                full-width onChange={(e)=>{setAmount(e.target.value)}}
                    />
                    <TextField  style={{width: "80%", fontSize: "2rem", marginBottom: 20}} variant="outlined" required={true} type="password" label={"Password"}
                                full-width onChange={(e)=>{setUserPass(e.target.value)}}
                    />
                    <p>We require your password as an added layer of security</p>
                    <a onClick={(e) => {sendMoney()}} className={"cav-button mb-2"}>
                        Start Transfer
                    </a>
                        <a onClick={(e) => {props.closefunc()}} className={"cav-button"}>
                            Close
                        </a>
                </div>
            </div>
        );
}



export default BankTransferModal;
