import React, {useEffect, useState} from 'react';
import './QuickSendIcon.css'
import NickHeadshot from "../../static/images/NickHeadshot.jpg";
import LinkedInLogo from "../../static/images/social-icons/icons8-linkedin.svg"
import MailLogo from "../../static/images/social-icons/icons8-mail.svg"
import CreateTransactionModal from "../create-transaction-modal/CreateTransactionModal";
export type QuickSendProps = {
    name: string,
    className: string,
    userId: string
}

function QuickSendIcon(props: QuickSendProps) {

    const[isMVisible, setMVisible] = useState(false);
useEffect(()=>{console.log(isMVisible)}, [isMVisible])
  return (
      <div  style={{ borderRadius: "10px",padding: 0, textAlign: 'center', maxWidth: "30%"}} className={props.className+" quick-send-card p-1"}>
          <CreateTransactionModal isShown={isMVisible} closefunc={ () => {setMVisible(false)} } prefillId={props.userId}/>
          <div  onClick={() => setMVisible(true)}>
              <img style={{width: "6vw",  height: "6vw", borderRadius: "100%", objectFit: "cover"}}
                   src={process.env.REACT_APP_API_URL+`/api/Account/${props.userId}/profilepic`}/>
              <div style={{padding: "10px"}}>
                  <p className={"p-0 m-0"} style={{fontWeight: "lighter"}}>{props.name}</p>
              </div>
          </div>
    </div>
  );
}

export default QuickSendIcon;
