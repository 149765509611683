import React, {useEffect, useState} from 'react';
import './Dashboard.scss';
import {useDispatch, useSelector} from "react-redux";
import {createSessionKey, SessionState} from "../../reducers/sessionReducer";
import {getUser, User, UserState} from "../../reducers/userReducer";
import Placeholder from "../../static/images/NickHeadshot.jpg"
import {getNextFiveTransactions, Transaction, TransactionState} from "../../reducers/transactionReducer";
import TransactionCard from "../../components/transaction-card/TransactionCard";
import QuickSendIcon from "../../components/quick-send-icon/QuickSendIcon";
import CreateTransactionModal from "../../components/create-transaction-modal/CreateTransactionModal";
import BankTransferModal from "../../components/bank-transfer-modal/BankTransferModal";
import TrendsCard from "../../components/trends-card/TrendsCard";
import LargePhotograph from "../../static/images/large-photo-min-2.jpg"
function Dashboard() {
    const [createTransactionVisibility, setCreateTransactionVisibility] = useState(false);
    const [bankTransferVisibility,setBankTransferVisibility] = useState(false);
    const [adding,setAdding] = useState(false);

    const user: User | null = useSelector<any, UserState["user"]>((state) => state.user.user);
    const transactions: Transaction[] | null = useSelector<any, TransactionState["transactions"]>((state) => state.transactions.transactions);

    const isLoggedIn: boolean = useSelector<any, SessionState["isLoggedIn"]>((state) => state.session.isLoggedIn)

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    const dispatch = useDispatch()
    useEffect(() => {
        if(!isLoggedIn)
            window.location.href = "/login"
        dispatch(getUser())
        dispatch(getNextFiveTransactions())
    }, [isLoggedIn])

    useEffect(() => {
        setTimeout(dispatch(getNextFiveTransactions()), 500)
    }, [createTransactionVisibility])

    return (
        <div>
            <div style={{backgroundImage: `url(${LargePhotograph})`, backgroundSize: "cover", zIndex:  -1, width: "100%", height: 350, position: "absolute"}}>

            </div>
            <div style={{backgroundImage: `linear-gradient(to bottom, rgb(0,0,0, .3), rgb(0,0,0, .1))`, backgroundSize: "cover", zIndex:  -1, width: "100%", height: 350, position: "absolute"}}>

            </div>
      <div style={{padding: "5vw", textAlign: 'center', minHeight: "100vh"}}>
          {isLoggedIn && user != null && transactions != null &&

              <div style={{}}>

          <BankTransferModal isShown={bankTransferVisibility} closefunc={() => {setBankTransferVisibility(false)}} addingMoney={adding}/>
          <p style={{marginBottom: 0, color: "white", fontSize: "2rem", transform: "translateY(15px)"}}>Welcome</p>
          <h1 style={{fontWeight: 'bold', color: "white", fontSize: "4rem",marginTop: 0}}>{user?.firstName} {user?.lastName}</h1>
          <div style={{marginTop: 75, paddingLeft: "10vw", paddingRight: "10vw"}} className={"row justify-content-center"}>
              <div style={{ height: 'min-content', padding: 5, paddingLeft: 50}} className={"cav-card col-md-8 mt-2"}>
                  <h1 className={"font-weight-bold m-0"}>Balance</h1>
                  <h3 style={{ textAlign: 'right', fontSize: 65}} className={"m-0 font-weight-light blue-text"}>{formatter.format(user?.balance || 0)}</h3>
                  <p className={"text-right font-weight-light font m-0"}>All funds in your account are not real and are for testing purposes only.</p>
                  <p className={"text-right font-weight-light m-0"}>You cannot add funds from a bank account at this time</p>
                  {/*{user?.linkedBank != null && <div className={"text-right"}><a href={"#"} onClick={() => {setAdding(true); setBankTransferVisibility(true)}}>Add Funds</a> | <a href={"#"} onClick={() => {setAdding(false); setBankTransferVisibility(true)}}>Withdraw Funds</a></div>}*/}
                  {/*{user?.linkedBank == null && <div className={"text-right"}><a href={"#"}>Link a Bank</a></div>}*/}
              </div>
              <div style={{padding: 20,  flexDirection: "column", display: "flex"}} className={"ml-md-2 cav-card col-md-3 col-12 justify-content-center mt-2"}>
                  <div>
                      <a href={"#"} onClick={(e) => {setCreateTransactionVisibility(true)}}>Send Money</a>
                      <CreateTransactionModal isShown={createTransactionVisibility} closefunc={() => {setCreateTransactionVisibility(false)}}/>
                      <hr/>
                  </div>

                  <div>
                      <p className={"font-weight-light"}><a className={"a-disabled font-weight-bold"}>Request Money</a> Coming Soon!</p>
                      <hr/>
                  </div>

                  <div>
                      <a href={"/settings"}>Settings</a>
                  </div>
              </div>
          </div>
          <div style={{paddingLeft: "10vw", paddingRight: "10vw"}} className={"row justify-content-center mt-2"}>
              <div style={{width: 400, textAlign: "center", height: "min-content"}} className={"cav-card pt-2 col-12 col-md-6"}>
                  <h2 style={{fontWeight: "bold", textAlign: "left", marginBottom: 10}}>Transactions</h2>
                  {isLoggedIn && transactions != null && transactions.slice(0,4).map((transaction, i, t)=> <TransactionCard isLast={i == t.length - 1} transaction={transaction}/>)}
                  {/*<div style={{ width: "100%"}} className={"row align-content-center justify-content-center d-flex ml-0 mr-0"}>*/}
                  {/*  <a className={"p-2"} href={"/transactions"}>View All Transactions</a>*/}
                  {/*</div>*/}
              </div>

              <div className={"col-md-5 col-12 m-md-0 p-0 mt-md-0 mt-2 ml-md-2"}>
                  <div style={{marginTop: 10,height: "min-content", paddingLeft: 50, width: "100%"}} className={" p-3 cav-card m-0"}>
                      <h2 style={{fontWeight: "bold"}}>Quick Send</h2>
                      <div className={"row pt-2 justify-content-center"}>
                        <QuickSendIcon name={"Benjamin Franklin"} userId={"ben"} className={"col-md-4 col-6"}/>
                        <QuickSendIcon name={"Elizabeth Jennings"} userId={"ejennings"} className={"col-md-4 col-6"}/>
                        <QuickSendIcon name={"Nicholas Orlowsky"} userId={"nickorlow"} className={"col-md-4 col-6"}/>
                      </div>
                  </div>

                  <TrendsCard/>
              </div>
          </div>
              </div>}
      </div>
        </div>
  );
}

export default Dashboard;
