import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {SessionState} from "../../reducers/sessionReducer";

interface analReport {
    amountSent: number,
    amountRecieved: number,
    netAmount: number
}

class AnalCollection extends Array {
    sum(key: string) {
        return this.reduce((a, b) => a + (b[key] || 0), 0);
    }
}

export default function TrendsCard() {
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    const userId: string = useSelector<any, string>((state) => state.session.accountId)
    const sessionId: string = useSelector<any, string>((state) => state.session.userKey.id)
   const [days, setDays] = useState([])
    useEffect(() => {
        getUser()
    }, [])



    async function getUser() {
        var date = new Date();
        var nowUtc =  new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
            date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()));

        fetch(process.env.REACT_APP_API_URL+`/api/user/analytics/daybyday/${userId}/${sessionId}/?startDate=${nowUtc.toISOString()}&days=7`, {
            method: 'GET',
            headers: {
                Authorization: 'MalibuBeach',
                'Content-type': 'application/json'
            }
        }).then(async (response) => {
            if(response.status!==200 && response.status!==201)
            {

            }
            setDays(await response.json())
console.log(days)
            //setUser(await response.json())
        });
    }

    return (
        <div style={{marginTop: 10,height: "max-content", padding: 20, paddingLeft: 50, width: "100%"}} className={"cav-card"}>
            <h2 style={{fontWeight: "bold"}}>Trends</h2>
            <div className={"row pt-2 pl-3 font-weight-normal justify-content-center text-center"}>
                <div className={"col-lg-4 col-6"}>
                    <h3 className={"blue-text"}>{formatter.format(days.reduce((a, b) => a + (b["amountSent"] || 0), 0))}</h3>
                    <p>spent this week</p>
                </div>
                <div className={"col-lg-4 col-6"}>
                    <h3 className={"green-text"}>{formatter.format(days.reduce((a, b) => a + (b["amountRecieved"] || 0), 0))}</h3>
                    <p>received this week</p>
                </div>
                <div className={"col-lg-4 col-6"}>
                    <h3 className={"blue-text"}>{formatter.format(days.reduce((a, b) => a + (b["netAmount"] || 0), 0))}</h3>
                    <p>balance change this week</p>
                </div>
            </div>
        </div>
    );

}
