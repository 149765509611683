import React from 'react';
import BigImageDark from '../../static/images/BigImageDark.jpg';
import "animate.css/animate.min.css";
import LegalImage from '../../static/images/illustrations/Justice-amico.svg';
import ScrollAnimation from 'react-animate-on-scroll';
import SecureImage from '../../static/images/illustrations/Security-amico.svg';
import HiddenImage from '../../static/images/illustrations/Hidden-amico.svg';
import './Home.scss';
import {BrandName} from "../../static/data/constants";
import Logo from "../../static/images/CavCashLogoNewNew2021.png";
import AstroCard from "../../components/astro-card/AstroCard";

function Home() {


    return (
        <div className="Home">


            <div style={{
                height: "100vh",
                display: "table-cell",
                verticalAlign: "middle",
                maxWidth: "100%",
                backgroundColor: "black",
                overflow: "hidden"
            }}>

                <div style={{display: "inline-block"}}>
                    <div style={{
                        position: 'absolute',
                        alignContent: 'center',
                        display: "inline-block",
                        height: "10vh",
                        textAlign: 'center',
                        width: "100%",
                        zIndex: 50,
                        top: 0,
                        fontWeight: 'bold',
                        marginTop: "10vh",
                        animation: "fade-up 2s forwards"
                    }}
                    >
                        <img src={Logo} style={{width: "40vh"}}/>
                        <h1 style={{color: 'white', fontWeight: 'lighter', fontSize: 30, marginTop: 10}}>The <b>secure</b> payment processor</h1>

                        <div className={"row"}
                             style={{justifyContent: "center", width: "100%", marginLeft: 0, marginRight: 0}}>
                            <div className={"cav-button"} style={{backgroundColor: "#EB0027", color: "white", borderRadius: 5 , paddingLeft: 10, paddingRight: 10}}
                            onClick={() => {
                                window.location.href = "/register"
                            }}>
                                <p style={{marginBottom: 2}}>Create an Account</p>
                            </div>
                        </div>

                    </div>

                    <div
                         style={{height: "100vh", width: "100%", position: "absolute", left:0, top:0, zIndex: -5,backgroundColor: "black",}}/>

                    <div className={"big-image"}
                         style={{height: "100vh", width: "100%", position: "absolute", left:0, top:0, zIndex: -5,backgroundImage: `url("${BigImageDark}")`, backgroundSize: "cover"}}/>

                </div>
            </div>
            <div style={{paddingTop: 10, margin: 0, paddingBottom: 10, justifyContent: 'center', alignContent: 'center'}}>
                <h1>What makes CavCash different?</h1>
                <ScrollAnimation animateIn="animate__fadeIn" duration={1} offset={50} delay={200} className={"justify-content-center d-flex m-5"}>
                    <div className={"row text-left"} style={{animation: "fade-in 1s forwards", width: 1200}}>
                        <h2 className={"col-md-2"}>Private</h2>
                        <p className={"col-md-10"} style={{fontSize: 20}}>{BrandName} does not sell your data tor 3rd party advertisers like PayPal and Venmo do.</p>
                    </div>
                </ScrollAnimation>

                <ScrollAnimation animateIn="animate__fadeIn" duration={1.5} offset={50} delay={200} className={"justify-content-center d-flex m-5"}>
                    <div className={"row text-left"}  style={{animation: "fade-in 1s forwards", width: 1200}}>
                        <h2 className={"col-md-2"}>Secure</h2>
                        <p className={"col-md-10"} style={{fontSize: 20}}>{BrandName} uses the latest encryption algorithms and keeps a small codebase to reduce our attack surface.</p>
                    </div>
                </ScrollAnimation>

                <ScrollAnimation animateIn="animate__fadeIn" duration={2} offset={50} delay={200} className={"justify-content-center d-flex m-5"}>
                    <div className={"row text-left"}  style={{animation: "fade-in 1s forwards", width: 1200}}>
                        <h2 className={"col-md-2"}>Fair</h2>
                        <p className={"col-md-10"} style={{fontSize: 20}}>{BrandName} looks at disputes from both sides, not always just siding with the buyer like PayPal. This should ease seller's worried about chargeback fraud. </p>
                    </div>
                </ScrollAnimation>
            </div>

            <AstroCard/>

            <div className={"row"} style={{justifyContent: "center", margin: 0, paddingTop: 30, paddingBottom: 30}}>
                <div style={{maxWidth: "75vw", textAlign: "left"}}>
                    <h1>Frequently Asked Questions</h1>
                    <hr/>

                    <h4 style={{fontWeight: "bold"}}>What is {BrandName}?</h4>
                    <p>
                        {BrandName} is a payment processor, like PayPal or Venmo that allows you to pay online with ease.
                    </p>

                    <h4 style={{fontWeight: "bold"}}>Is {BrandName} launched?</h4>
                    <p>
                        No, {BrandName} is currently only operating in 'test mode'. There are currently no plans to resume operations.
                    </p>

                    <h4 style={{fontWeight: "bold"}}>Is {BrandName} a Crypto Currency?</h4>
                    <p>
                        {BrandName} is <b>not</b> a cryptocurrency.
                    </p>
                </div>
            </div>

        </div>
    );
}

export default Home;
