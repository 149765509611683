import React from 'react';
import logo from '../../logo.svg';
import './Privacy.css';
import Pdf from '../../static/documents/Roomy_Privacy_Policy.pdf';
function Privacy() {
    return (
        <div style={{padding: 50}}>
            <h2>Privacy Policy</h2>
            {/*<a href={Pdf}>Read as PDF</a>*/}
            <hr/>
            <div >


                <div>

                    <p>Protecting your private information is our priority. This Statement of Privacy
                        applies to CavCash, and CAVCASH INC and governs data collection and usage. For the purposes
                        of this Privacy Policy, unless otherwise noted, all references to CAVCASH INC include
                        orlowsoft.com and cavcash.com, and CavCash. The CavCash application is a payment processing
                        application. By using the CavCash application, you consent to the data practices
                        described in this statement.</p>
                    <p style={{fontWeight: 'bold'}}>Collection of your Personal Information</p>
                    <p>In order to better provide you with products and services offered, CavCash may
                        collect personally identifiable information, such as your:</p>
                    <p><span>-</span><span>First and Last Name</span>
                    </p>
                    <p><span>-</span><span>Mailing Address</span></p>

                    <p ><span >-</span><span >Phone Number</span></p>
                    <p ><span >-</span>
                        <span >E-mail</span>
                        Address
                    </p>
                    <p ><span >-</span><span
                        >Website Usage Information</span></p>
                    <p >If you purchase CavCash's products and services, we collect billing and credit
                        card information. This information is used to complete the purchase transaction.</p>
                    <p >Please keep in mind that if you directly disclose personally identifiable
                        information or personally sensitive data through CavCash's public message boards, this information
                        may be collected and used by others.</p>
                    <p >We do not collect any personal information about you unless you voluntarily
                        provide it to us. However, you may be required to provide certain personal information to us
                        when you elect to use certain products or services. These may include: (a) registering for an
                        account; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c)
                        signing up for special offers from selected third parties; (d) sending us an email message; (e)
                        submitting your credit card or other payment information when ordering and purchasing products
                        and services. To wit, we will use your information for, but not limited to, communicating with
                        you in relation to services and/or products you have requested from us. We also may gather
                        additional personal or non-personal information in the future.
                    </p>
                    <p  style={{fontWeight: 'bold'}}>Use of your Personal Information</p>
                    <p >CavCash collects and uses your personal information to operate and deliver the
                        services you have requested.</p>
                    <p >CavCash may also use your personally identifiable information to inform you of
                        other products or services available from CavCash and its affiliates.</p>
                    <p  style={{fontWeight: 'bold'}}>Sharing Information with Third Parties</p>

                    <p >CavCash does not sell, rent or lease its customer lists to third parties.</p>
                    <p >CavCash may share data with trusted partners to help perform statistical
                        analysis, send you email or postal mail, provide customer support, or arrange for deliveries.
                        All such third parties are prohibited from using your personal information except to provide
                        these services to CavCash, and they are required to maintain the confidentiality of your
                        information.</p>
                    <p >CavCash may disclose your personal information, without notice, if required to
                        do so by law or in the good faith belief that such action is necessary to: (a) conform to the
                        edicts of the law or comply with legal process served on CavCash or the site; (b) protect and
                        defend the rights or property of CavCash; and/or (c) act under exigent circumstances to protect
                        the personal safety of users of CavCash, or the public.</p>
                    <p  style={{fontWeight: 'bold'}}>Security of your Personal Information</p>
                    <p >CavCash secures your personal information from unauthorized access, use, or
                        disclosure. CavCash uses the following methods for this purpose:</p>
                    <p ><span >-</span><span >SSL Protocol</span></p>
                    <p >When personal information (such as a credit card number) is transmitted to
                        other websites, it is protected through the use of encryption, such as the Secure Sockets Layer
                        (SSL) protocol.</p>
                    <p >We strive to take appropriate security measures to protect against
                        unauthorized access to or alteration of your personal information. Unfortunately, no data
                        transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a
                        result, while we strive to protect your personal information, you acknowledge that: (a) there
                        are security and privacy limitations inherent to the Internet which are beyond our control; and
                        (b) security, integrity, and privacy of any and all information and data exchanged between you
                        and us through this Site cannot be guaranteed.</p>
                    <p  style={{fontWeight: 'bold'}}>Right to Deletion</p>
                    <p >Subject to certain exceptions set out below, on receipt of a verifiable
                        request from you, we will:</p>
                    <p ><span >•</span><span >Delete your personal information from our records; and</span>
                    </p>
                    <p ><span >•</span><span >Direct any service providers to delete your personal information from their records.</span>
                    </p>
                    <p >Please note that we may not be able to comply with requests to delete your
                        personal information if it is necessary to:</p>
                    <p ><span >•</span><span >Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us;</span>
                    </p>
                    <p ><span >•</span><span >Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;</span>
                    </p>
                    <p ><span >•</span><span >Debug to identify and repair errors that impair existing intended functionality;</span>
                    </p>
                    <p ><span >•</span><span >Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law;</span>
                    </p>
                    <p ><span >•</span><span >Comply with the California Electronic Communications Privacy Act;</span>
                    </p>
                    <p ><span >•</span><span
                        >Engage in public or </span>
                        peer-reviewed
                        scientific, historical, or statistical research in the public interest that adheres to all other
                        applicable ethics and privacy laws, when our deletion of the information is likely to render
                        impossible or seriously impair the achievement of such research, provided we have obtained your
                        informed consent;
                    </p>
                    <p ><span >•</span><span >Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;</span>
                    </p>
                    <p ><span >•</span><span >Comply with an existing legal obligation; or</span>
                    </p>
                    <p ><span >•</span><span >Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.</span>
                    </p>
                    <p  style={{fontWeight: 'bold'}}>Children Under Thirteen</p>
                    <p >CavCash does not knowingly collect personally identifiable information from
                        children under the age of thirteen. If you are under the age of thirteen, you must ask your
                        parent or guardian for permission to use this application.</p>
                    <p  style={{fontWeight: 'bold'}}>Disconnecting your CavCash Account from Third Party Websites</p>
                    <p >You will be able to connect your CavCash account to third party accounts. BY
                        CONNECTING YOUR CAVCASH ACCOUNT TO YOUR THIRD PARTY ACCOUNT, YOU ACKNOWLEDGE AND AGREE THAT YOU
                        ARE CONSENTING TO THE CONTINUOUS RELEASE OF INFORMATION ABOUT YOU TO OTHERS (IN ACCORDANCE WITH
                        YOUR PRIVACY SETTINGS ON THOSE THIRD PARTY SITES). IF YOU DO NOT WANT INFORMATION ABOUT YOU,
                        INCLUDING PERSONALLY IDENTIFYING INFORMATION, TO BE SHARED IN THIS MANNER, DO NOT USE THIS
                        FEATURE. You may disconnect your account from a third party account at any time. Users may learn
                        how to disconnect their accounts from third-party websites by visiting their "My
                        Account" page. Users may also contact us via email or telephone.
                    </p>
                    <p >
                        E-mail
                        Communications
                    </p>
                    <p >From time to time, CavCash may contact you via email for the purpose of
                        providing announcements, promotional offers, alerts, confirmations, surveys, and/or other
                        general communication. In order to improve our Services, we may receive a notification when you
                        open an email from CavCash or click on a link therein.</p>
                    <p >If you would like to stop receiving marketing or promotional communications
                        via email from CavCash, you may opt out of such communications by clicking on the UNSUBSCRIBE
                        button.</p>
                    <p  style={{fontWeight: 'bold'}}>External Data Storage Sites</p>
                    <p >We may store your data on servers provided by third party hosting vendors
                        with whom we have contracted.</p>

                    <p  style={{fontWeight: 'bold'}}>Software Rights Notice</p>
                    <p>
                        The underlying source code for this website and all CavCash services as well as the full rights and ownership of that code are the sole property of Nicholas Orlowsky.
                        <br/><br/>
                        CavCash Inc has a perpetual license to use that code which is revocable at any time. Upon revocation, Nicholas Orlowsky will take over operation of the
                        CavCash platform including any user data stored on the platform. This transition should not affect the end-users of CavCash or any of it's associated services in any way.
                    </p>

                    <p  style={{fontWeight: 'bold'}}>Changes to this Statement</p>

                    <p >CavCash reserves the right to change this Privacy Policy from time to time. We
                        will notify you about significant changes in the way we treat personal information by sending a
                        notice to the primary email address specified in your account, by placing a prominent notice on
                        our application, and/or by updating any privacy information. Your continued use of the
                        application and/or Services available after such modifications will constitute your: (a)
                        acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that
                        Policy.</p>

                    <p  style={{fontWeight: 'bold'}}>Contact Information</p>
                    <p >CavCash welcomes your questions or comments regarding this Statement of
                        Privacy. If you believe that CavCash has not adhered to this Statement, please contact CavCash
                        at:</p>
                    <p >CAVCASH INC</p>
                    <p >10601 Clarence Drive Suite 250</p>
                    <p >Frisco, Texas 75033</p>
                    <p >Email Address: contact@cavcash.com</p>
                    <p >Telephone number: 5127311307</p>
                    <p >Effective as of August 13, 2021</p>
                </div>

            </div>
        </div>
    );
}

export default Privacy;
