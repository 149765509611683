import 'date-fns';
import React, { useState} from 'react';
import {TextField} from "@material-ui/core";
import {getUser, User} from "../../reducers/userReducer";
import {useDispatch, useSelector} from "react-redux";
import {KeyboardDatePicker,
    MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import "./UpdateInfoModal.scss"


function UpdateInfoModal(props: {isShown: boolean, closefunc: any, user: User}) {

    const dispatch = useDispatch()
    const [fname, setFname] = useState(props.user.firstName);
    const [lname, setLName] = useState(props.user.lastName);
    const [email, setEmail] = useState(props.user.accountEmail);
    const [date, setDate] = useState<Date | null>(new Date(props.user.birthDate));
    const [address, setAddress] = useState<any>(null);
    const sessionKey: string = useSelector<any, string>((state) => state.session.userKey.id);

    function updateInfo() {
        fetch(process.env.REACT_APP_API_URL+`/api/user/${props.user.accountID}/${sessionKey}`, {
            method: 'PATCH',
            body: JSON.stringify({
                accountEmail: email === props.user.accountEmail ? null : email,
                firstName: fname === props.user.firstName ? null : fname,
                lastName: lname === props.user.lastName ? null : lname,
                birthDate: date?.toISOString() === props.user.birthDate ? null : date?.toISOString(),
                homeAddress: address != null ? {
                        googleLocationId: address.value.place_id
                    }
                : null
            }),
            headers: {
                Authorization: 'MalibuBeach',
                'Content-type': 'application/json'
            }
        }).then(async (response) => {
            if (response.status == 200) {
                dispatch({
                    type: "SHOW_MODAL",
                    payload: {message: "Updated your information!"}
                })
            } else
                dispatch({type: "SHOW_MODAL", payload: {message: "We were unable to update your info."}})
        });
    }


        return (
            <div style={{backgroundColor: "rgba(0,0,0,.5)", position: "fixed", left: 0, top: 0, height: "100vh", width: "100%",alignItems: "center", display: props.isShown ? "flex" : "none", zIndex: 420, justifyContent: "center"}}>
                <div style={{zIndex: 421, width: "75vh", height: "max-content", backgroundColor: "white", borderRadius: 20,  padding: 10 }}>
                    <h2>Update Account Information</h2>
                    <p className={"font-weight-normal"}>All data must be as it appears on your government-issued ID</p>

                    <div className={"row justify-content-center"}>
                        <div className={"row"} style={{width: "80%"}}>
                            <TextField className={"col-md-6 pr-1"}  style={{fontSize: "2rem", marginBottom: 20}} variant="outlined" value={fname} required={true} label={"First Name"}
                                        full-width onChange={(e)=>{setFname(e.target.value)}}
                            />
                            <TextField className={"col-md-6 pl-1"} style={{fontSize: "2rem", marginBottom: 20}} variant="outlined" value={lname} required={true} label={"Last Name"}
                                        full-width onChange={(e)=>{setLName(e.target.value)}}
                            />
                        </div>
                        <div className={"row"} style={{width: "80%"}}>
                        <TextField className={"col-6"}  style={{ fontSize: "2rem", marginBottom: 20}} variant="outlined" required={true} label={"E-Mail Address"} value={email}
                                    full-width onChange={(e)=>{setEmail(e.target.value)}}
                        />
                        <div className={"col-6"}>
                        <MuiPickersUtilsProvider  utils={MomentUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/DD/yyyy"
                                id="date-picker-inline"
                                label="Birthdate"
                                value={date}
                                style={{width: "103%", margin: 0}}
                                onChange={(e : MaterialUiPickersDate) => {e != null && setDate(new Date(e.toISOString()));}}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            </MuiPickersUtilsProvider>
                        </div>

                        </div>
                        <div style={{width: "80%"}}>
                            <p>Address</p>
                        <GooglePlacesAutocomplete
                            selectProps={{
                                value: address,
                                onChange: (e : any) => {
                                    setAddress(e)
                                },
                            }}
                            full-width
                            apiKey="AIzaSyAca1zHyzQo3KUEhOv--7jOsXL7HfwnOD4"
                        />
                        </div>
                        <a  onClick={(e) => {updateInfo()}} style={{color: "lightgreen"}} className={" m-2 cav-button col-10"}>
                            Update Information
                        </a>
                        <a  onClick={(e) => {props.closefunc()}} className={"m-2 cav-button col-10"}>
                            Close
                        </a>
                    </div>



                </div>

            </div>
        );
}



export default UpdateInfoModal;
