export interface NewTransaction {

    toID: string,
    amount: number,
    description: string
}

export interface NewTransactionState {
    transaction: NewTransaction | null,
    status: "none" | "success" | "fail",
    password: string
}

const initialState : NewTransactionState = {
    transaction: null,
    status: "none",
    password: ""
}

type Action = {type: "SET_TRANSACTION" | "FAIL_TRANSACTION" | "SUCCESS_TRANSACTION" | "CLEAR_TRANSACTION_STATUS", payload: {transaction: NewTransaction, password: string}}

export const sendMoneyReducer = (state:NewTransactionState = initialState, action: Action) => {
    switch(action.type) {

        case "SET_TRANSACTION":
            return {...state, transaction:action.payload.transaction, password: action.payload.password}

        case "FAIL_TRANSACTION":
            return {...state, status: "fail"}

        case "SUCCESS_TRANSACTION":
            return {...state, status: "success"}

        case "CLEAR_TRANSACTION_STATUS":
            return {...state, status: "none"}

        default:
            return {...state}
    }
}

export const makeTransaction = () => async(dispatch: any, getState: any) => {
    var sessionState = getState().session;
    var newTransactionState: NewTransactionState = getState().sendMoney;

    await fetch(process.env.REACT_APP_API_URL+'/api/Account/transactions/'+sessionState.accountId+'/'+newTransactionState.password, {
        method: 'POST',
        body: JSON.stringify(newTransactionState.transaction),
        headers: {
            Authorization: 'MalibuBeach',
            'Content-type': 'application/json'
        }
    }).then(async (response) => {
        if(response.status!==200 && response.status!==201)
        {
            throw new Error(response.status+"")
        }
        dispatch({type: "SUCCESS_TRANSACTION"})
    }).catch((e) => {
        dispatch({type: "FAIL_TRANSACTION"})
    });
}

