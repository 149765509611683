export interface News {
    blogID: string,
    title: string,
    author: string,
    createdDate: string,
    content: string,
    coverImage: string
}

export interface NewsState {
    news: News[]
}

const initialState : NewsState = {
    news: []
}

type Action = {type: "SET_BLOGS", payload: {news: News[]}}

export const newsroomReducer = (state:NewsState = initialState, action: Action) => {
    switch(action.type) {

        case "SET_BLOGS":
            return {...state, news: action.payload.news}

        default:
            return {...state}
    }
}

export const getNews = () => async(dispatch: any, getState: any) => {
    await fetch(process.env.REACT_APP_API_URL+'/api/Blog/10', {
        method: 'GET',
        headers: {
            Authorization: 'MalibuBeach',
            'Content-type': 'application/json'
        }
    }).then(async (response) => {
        if(response.status!==200 && response.status!==201) {

        }
        else {
            dispatch({type: "SET_BLOGS", payload: {news: await response.json()}})
        }
    });
}

