import {createStore, applyMiddleware, combineReducers} from 'redux'
import {sessionReducer} from './reducers/sessionReducer'
import thunk from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";
import {modalReducer} from "./reducers/modalReducer";
import {userReducer} from "./reducers/userReducer";
import {registrationReducer} from "./reducers/registrationReducer";
import {transactionReducer} from "./reducers/transactionReducer";
import {sendMoneyReducer} from "./reducers/sendMoneyReducer";
import {bankTransferReducer} from "./reducers/bankReducer";
import {newsroomReducer} from "./reducers/newsroomReducer";

const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if(serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (e) {
        return undefined;
    }
};

const saveState = (state: any) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch (e) {
        // Ignore write errors;
    }
};

const persistedState = loadState();



export const store = createStore(combineReducers( { session: sessionReducer, modal: modalReducer, user: userReducer, registration: registrationReducer, transactions: transactionReducer, sendMoney: sendMoneyReducer, bankTransfer: bankTransferReducer, news: newsroomReducer}),persistedState, composeWithDevTools(applyMiddleware(thunk)))


store.subscribe(() => {
    saveState(store.getState());
});
