import React, {useEffect, useState} from 'react';
import './Forgot.scss';
import Logo from "../../static/images/crypto-logo.svg";
import {BrandName} from "../../static/data/constants";
import {TextField} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {SessionState, createSessionKey} from "../../reducers/sessionReducer";
import PasswordCreator from "../../components/password-creator/PasswordCreator";

function Forgot() {
    const [userId, setUserId] = useState('');
    const [code, setCode] = useState('');
    const [userPass, setUserPass] = useState('');
    const [hasCode, setHasCode] = useState(false);
    const [hasFullCode, setHasFullCode] = useState(false);
    const isLoggedIn: boolean = useSelector<any, SessionState["isLoggedIn"]>((state) => state.session.isLoggedIn);
    const dispatch = useDispatch();

    useEffect(() => {
        var hash = window.location.hash.substr(1);
        var items: string[] = hash.split('&');
        items.map((s) => {
            var param: string = s.split('=')[0];
            var val: string = s.split('=')[1];

            switch(param) {
                case "hasCode":
                    if(val === "true")
                        setHasCode(true);
                    break;

                case "hasFullCode":
                    if(val === "true") {
                        setHasFullCode(true);
                        setHasCode(true);
                    }
                    break;

                case "username":
                    setUserId(val);
                    break;

                case "code":
                    setCode(val);
                    break;
            }
        })

        if(isLoggedIn)
            window.location.href = "/dashboard"
    }, [isLoggedIn, window.location])



    function requestCode() {
        if(check()) {
            fetch(process.env.REACT_APP_API_URL+`/api/user/password/${userId}`, {
                method: 'PUT',
                headers: {
                    Authorization: 'MalibuBeach',
                    'Content-type': 'application/json'
                }
            }).then(async (response) => {
                if (response.status == 200)
                    setHasCode(true)
                else
                    dispatch({type: "SHOW_MODAL", payload: {message: "We were unable to process a password reset."}})
            });
        }
    }

    function resetPassword() {
        if(userPass !== '') {
            fetch(process.env.REACT_APP_API_URL+`/api/user/password/${userId}/${code}`, {
                method: 'PUT',
                body: JSON.stringify({
                    accountPassword: userPass
                }),
                headers: {
                    Authorization: 'MalibuBeach',
                    'Content-type': 'application/json'
                }
            }).then(async (response) => {
                if (response.status == 200) {
                    dispatch({
                        type: "SHOW_MODAL",
                        payload: {message: "Successfully reset password!", callback: gotoLogin}
                    })
                } else
                    dispatch({type: "SHOW_MODAL", payload: {message: "We were unable to reset your password."}})
            });
        }
        else {
            dispatch({type: "SHOW_MODAL", payload: {message: "The password you entered was not strong enough or did not match"}})
        }
    }

    function gotoLogin() {
        window.location.href = "/login";
    }

    function check() {
        if(userId == null || userId === "") {
            dispatch({type: "SHOW_MODAL", payload: {message: "Please enter a Username or E-Mail!"}})
            return false;
        }
        return true;
    }

    return (
      <div className="row" style={{minHeight: "100vh",padding: "50px", textAlign: 'center', justifyContent: "center"}}>
          <div id={"move-to-own-component-please!"}>
              <div className={"login-box " + (hasCode ? "d-none" : "")} style={{marginTop: "10vh"}}>
                  <h3>Password Reset</h3>
                      <TextField  style={{width: "80%", fontSize: "2rem", marginBottom: 20}} variant="outlined" required={true} label={"Username or E-Mail Address"}
                                  full-width onChange={(e) => {setUserId(e.target.value)}}
                      />

                  <a className={"cav-button"} style={{width: "80%", fontSize: "1rem", marginBottom: 20}} onClick={(e) => {requestCode()}}>Request Password Reset</a>
                  <a onClick={() => {
                      if(check()) {
                          setHasCode(true);
                      }}}>I have a password reset code</a>

              </div>

              <div className={"login-box " + (!hasCode ? "d-none" : "")} style={{marginTop: "10vh"}}>
                  <h3>Password Reset</h3>
                  <TextField  style={{width: "80%", fontSize: "2rem", marginBottom: 20}} variant="outlined" required={true} label={"Reset Code"} value={code}
                              full-width onChange={(e) => {setCode(e.target.value)}} className={hasFullCode ? "d-none" : ""}
                  />
                  <PasswordCreator passFunc={setUserPass} />
                  <a className={"cav-button"} style={{width: "80%", fontSize: "1rem", marginBottom: 20}} onClick={(e) => {resetPassword()}}>Request Password Reset</a>
                  <a onClick={() => {setHasCode(false)}}>I don't have a password reset code</a>
              </div>
          </div>
      </div>
  );
}

export default Forgot;
