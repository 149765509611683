import {TextField} from "@material-ui/core";
import React, {useEffect, useState} from "react";

export default function PasswordCreator(props: {passFunc: any}) {
    const [problems, setProblems] = useState<string[]>([]);

    const [cuserPass, setCUserPass] = useState('');
    const [userPass, setUserPass] = useState('');


    function validatePass(pass : string) {
        var problems: string[] = [];
        if (pass.length < 12) {
            problems.push('length')
        }
        if (pass.search(/[a-z]/) == -1) {
            problems.push('lowercase')
        }
        console.log(pass.search(/[A-Z]/))
        if (pass.search(/[A-Z]/) == -1) {
            problems.push('uppercase')
        }
        if (pass.search(/[0-9]/) == -1) {
            problems.push('number')
        }
        if (pass.search(/[#?!@$%^&*-]/) == -1) {
            problems.push('schar')
        }
        return problems;
    }

    useEffect(() => {
        setProblems(validatePass(userPass))
        if(problems.length === 0 && cuserPass === userPass)
            props.passFunc(cuserPass);
        else {
            props.passFunc('');
        }
    }, [userPass, cuserPass])

    return (
        <div className={"w-100"}>
            <TextField  style={{width: "100%", fontSize: "2rem", marginBottom: 20}} variant="outlined" required={true} type="password" label={"New Password"}
                        full-width onChange={(e)=>{setUserPass(e.target.value)}}
            />
            <div className={"text-center "+(problems.length == 0 ? "d-none" : "")}>
                <p>For your security, passwords must include:</p>
                <div className={"text-center"}>
                    <p className={"m-0 " + (problems.indexOf('uppercase') > -1 ? "requirement-failed" : "requirement-met")}>Uppercase letter</p>
                    <p className={"m-0 " + (problems.indexOf('lowercase') > -1 ? "requirement-failed" : "requirement-met")}>Lowercase letter</p>
                    <p className={"m-0 " + (problems.indexOf('number') > -1 ? "requirement-failed" : "requirement-met")}>Number</p>
                    <p className={"m-0 " + (problems.indexOf('schar') > -1 ? "requirement-failed" : "requirement-met")}>Special character</p>
                    <p className={"m-0 " + (problems.indexOf('length') > -1 ? "requirement-failed" : "requirement-met")}>12 Characters</p>
                </div>
            </div>
            <div className={"text-center "+(problems.length != 0 ? "d-none" : "")}>
                <p className={"requirement-met font-weight-bold"}>Your password is secure!</p>
            </div>
            <TextField  style={{width: "100%", fontSize: "2rem", marginBottom: 20}} variant="outlined" required={true} type="password" label={"Confirm Password"}
                        full-width onChange={(e)=>{setCUserPass(e.target.value)}}
            />
            <div className={"text-center "+(userPass === cuserPass ? "d-none" : "")}>
                <p className={"requirement-failed font-weight-bold"}>Passwords must match!</p>
            </div>
        </div>
    );
}
