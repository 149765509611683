import React from 'react';
import logo from '../../logo.svg';
import './NotFound.scss';
import HappyImage from "../../../static/images/illustrations/astronaut.svg";
function NotFound() {
  return (
    <div className="NotFound">
        <p>
            <img src={HappyImage} style={{height: 400}}/>

            <h1>We couldn't find this page.</h1>

        </p>
        <a
            className="cav-button"
            href="/"
        >Go Home</a>
    </div>
  );
}

export default NotFound;
