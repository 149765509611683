import React, {Dispatch, useEffect, useState} from 'react';
import './TransactionCard.css'
import {Transaction} from "../../reducers/transactionReducer";
import {Bank, User, UserState} from "../../reducers/userReducer";
import {useSelector} from "react-redux";
import TransactionModal from "../transaction-modal/TransactionModal";
import BankImage from "../../static/images/bank.jpeg"

function TransactionCard(props: {transaction: Transaction, isLast: boolean}) {
    const [isVisible, setVisibility] = useState(false);
    const userId: string | null = useSelector<any, string>((state) => state.user.user.accountID);
    const [user, setUser] : [null | User, Dispatch<any>] = useState(null);
    const isBank:boolean = props.transaction.fromBank != null || props.transaction.toBank != null
    const bank: Bank = props.transaction.fromBank == null ?  props.transaction.toBank : props.transaction.fromBank


    useEffect(() => {
        getUser()
    }, [])

    const isToMe = props.transaction.toID == userId
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    function showHide() {
        setVisibility(!isVisible);
    }

    async function getUser() {
        if(isBank)
            return;
        fetch(process.env.REACT_APP_API_URL+'/api/User/'+(isToMe ? props.transaction.fromID : props.transaction.toID), {
            method: 'GET',
            headers: {
                Authorization: 'MalibuBeach',
                'Content-type': 'application/json'
            }
        }).then(async (response) => {
            if(response.status!==200 && response.status!==201)
            {
                throw new Error(response.status+"")
            }
            setUser(await response.json())
        }).catch((e) => {

        });
    }

    return (
        <div>
            <TransactionModal isShown={isVisible} closefunc={() => {setVisibility(false)}} transaction={props.transaction} user={user} isToMe={isToMe}/>
            <div className={"transaction-card row pb-2 pt-2"} style={{ marginLeft: -10, marginRight: -10, borderRadius: 5}}onClick={(e) => {setVisibility(true)}}>
                <div className={"col-3"}>
                    <img style={{backgroundImage: "black", objectFit: "cover", width: "5vw", height: "5vw", borderRadius: "50%", objectPosition: "center center"}} className={"d-none p-0 m-0 d-md-block rounded-circle"} src={isBank ? BankImage : process.env.REACT_APP_API_URL+`/api/Account/${user!?.accountID}/profilepic`}/>
                </div>
                <div className={"row font-weight-normal col-md-9 col-12"}>
                    {!isBank && <p className={"col-8 m-0"}>{user!?.firstName} {user!?.lastName}</p>}
                    {isBank && <p className={"col-8 m-0"}>{bank.institution} {bank.type}</p>}
                    <p className={"col-4 m-0 text-right " + (isToMe ? 'green-text' : 'blue-text')}>{formatter.format(props.transaction.amount)}</p>
                    <p className={"col-12 m-0"} style={{color: 'grey'}}>{new Date(props.transaction.dateOccurred).toLocaleString()}</p>
                </div>
            </div>
            {props.isLast || <hr className={"m-0"}/>}
        </div>
    );
}

export default TransactionCard;
