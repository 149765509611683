
export interface SessionKey {
    id: string,
    expiryDate: string,
    authLevel: string,
    createdDate: string
}

export interface SessionState {
    accountId: string | null,
    accountPassword: string | null,
    // session key
    isLoggedIn: boolean,
    userKey: SessionKey | null
}

const initialState : SessionState = {
    accountId: null,
    accountPassword: null,
    isLoggedIn: false,
    userKey: null
}

type Action = {type: "CREATE_SESSION_INFO" | "SUCCESS_AUTH" | "FAIL_AUTH" | "DELETE_SESSION_INFO", payload: { accountId: string, accountPassword: string, userKey: SessionKey | null }}

export const sessionReducer = (state:SessionState = initialState, action: Action) => {
    switch(action.type) {
        case "CREATE_SESSION_INFO":
            return {...state, isLoggedIn: false, accountId: action.payload.accountId, accountPassword: action.payload.accountPassword}

        case "SUCCESS_AUTH":
            return {...state, isLoggedIn: true, accountId: action.payload.accountId,userKey: action.payload.userKey}

        case "FAIL_AUTH":
            return {...state, isLoggedIn: false, sessionKey: null, accountId: null, accountPassword: null}

        case "DELETE_SESSION_INFO":
            //TODO: Call API and invalidate session
            return {...state, isLoggedIn: false, sessionKey: null, accountId: null, accountPassword: null}

        default:
            return {...state}
    }
}

export const createSessionKey = () => async(dispatch: any, getState: any) => {
    var state = getState().session;
    var reqBody = {
        accountId: state.accountId,
        accountPassword: state.accountPassword
    }
    await fetch(process.env.REACT_APP_API_URL+'/api/User/auth?type=basic', {
        method: 'POST',
        body: JSON.stringify(reqBody),
        headers: {
            Authorization: 'MalibuBeach',
            'Content-type': 'application/json'
        }
    }).then(async (response) => {
        if(response.status!==200 && response.status!==201)
        {
            throw new Error(response.status+"")
        }
        dispatch({type: "SUCCESS_AUTH", payload: {accountId: reqBody.accountId, userKey: await response.json()}})
        window.location.href = "/dashboard"
    }).catch((e) => {
        dispatch({type: "SHOW_MODAL", payload: {message: "Failed to login"}})
        dispatch({type: "FAIL_AUTH"})
    });
}

