import React, {useEffect, useState} from 'react';
import './Settings.scss';
import {useDispatch, useSelector} from "react-redux";
import {SessionState} from "../../reducers/sessionReducer";
import {getUser, User, UserState} from "../../reducers/userReducer";
import UpdateInfoModal from "../../components/update-info-modal/UpdateInfoModal";

function Settings() {

    const [showingUpdate,setShowingUpdate] = useState<any>(false);
    const [imageReady,setImageReady] = useState<any>(false);
    const [image,setImage] = useState<any>(null);
    const user: User | null = useSelector<any, UserState["user"]>((state) => state.user.user);
    const isLoggedIn: boolean = useSelector<any, SessionState["isLoggedIn"]>((state) => state.session.isLoggedIn);
    const sessionState: SessionState = useSelector<any, SessionState>((state) => state.session);
    const dispatch = useDispatch()
    const hiddenFileInput = React.useRef(null);

    useEffect(() => {
        if(!isLoggedIn)
            window.location.href = "/login"
        dispatch(getUser())
    }, [isLoggedIn])

    useEffect(() => {
        if(!imageReady) {
            setImageReady(true)
            return;
        }
        convertBase64andUpload()
    }, [image])


    const convertBase64andUpload = () => {
        if(image == null)
            return;

        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(image)
            fileReader.onload = () => {
                resolve(fileReader.result);
                fetch(process.env.REACT_APP_API_URL+`/api/user/${sessionState.accountId}/${sessionState.userKey?.id}/profilepic`, {
                    method: 'POST',
                    body: JSON.stringify(
                        {
                            image: fileReader.result
                        }
                    ),
                    headers: {
                        Authorization: 'MalibuBeach',
                        'Content-type': 'application/json'
                    }
                }).then(async (response) => {
                    if(response.status !== 200 && response.status !== 201)
                        dispatch({type: "SHOW_MODAL", payload: {message: "We couldn't update your profile picture."}})
                    else
                        dispatch({type: "SHOW_MODAL", payload: {message: "Updated your profile picture!."}})
                });
            }
            fileReader.onerror = (error) => {
                dispatch({type: "SHOW_MODAL", payload: {message: "We couldn't update your profile picture."}})
            }
        })
    }

    return (
      <div style={{padding: "50px", textAlign: 'center', minHeight: "100vh"}}>
          <p style={{marginBottom: 0}}>{user?.firstName} {user?.lastName}'s</p>
          <h1 style={{fontWeight: 'bold', marginTop: 0}}>Settings</h1>
          <div className={"row justify-content-center"}>

              <div style={{height: 200, padding: 20, paddingLeft: 50, flexDirection: "column", display: "flex"}} className={"cav-card col-md-2 col-12 justify-content-center mt-2"}>
                  <div>
                      <a href={"/dashboard"}>Back to Dashboard</a>
                      <hr/>
                  </div>
                  <div>
                      <input ref={hiddenFileInput} hidden type="file" onChange={(e)=>{if(e.target.files != null) setImage(e.target.files[0])}} />
                      <button onClick={async (e) => {
                            if(hiddenFileInput != null && hiddenFileInput.current != null)
                                { // @ts-ignore
                                    hiddenFileInput.current.click();
                                }
                          }}>Change Profile Picture</button>

                      <hr/>
                  </div>

                  <div>
                      <p className={"font-weight-light"}><button className={"font-weight-bold"} onClick={() => {setShowingUpdate(true)}}>Update Account</button></p>
                      {user != null && <UpdateInfoModal isShown={showingUpdate} closefunc={() => {setShowingUpdate(false)}} user={user}/>}
                  </div>
              </div>

              <div style={{width: 400, height: 200, padding: 20, paddingLeft: 50}} className={"cav-card col-md-5 mt-2 m-2 row align-items-center"}>
                  <img alt="your profile pic" className={"col-3"} style={{backgroundImage: "black", borderRadius: "100%", height: 125, width: 125, objectFit: "cover"}}  src={process.env.REACT_APP_API_URL+`/api/Account/${user!?.accountID}/profilepic`}/>
                  <div className={"col-9 font-weight-normal"}>
                      <h1>{user?.firstName} {user?.lastName}</h1>
                      <p className={"mb-2"}>CavCash member since {user?.accountCreationDate !== undefined && (new Date(user?.accountCreationDate).getMonth()+ '/' + new Date(user?.accountCreationDate).getDay()+'/'+ new Date(user?.accountCreationDate).getFullYear())}</p>
                      <p className={"m-0"}>{user?.homeAddress.streetAddress}</p>
                      <p className={"m-0"}>{user?.homeAddress.city}, {user?.homeAddress.state} {user?.homeAddress.zip}</p>
                  </div>
              </div>

          </div>

      </div>
  );
}

export default Settings;
