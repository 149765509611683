import React, {useEffect, useState} from 'react';
import './Login.scss';
import Logo from "../../static/images/CavCashLogoNewNew2021.png";
import {BrandName} from "../../static/data/constants";
import {TextField} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {SessionState, createSessionKey} from "../../reducers/sessionReducer";

function Login() {
    const [userId, setUserId] = useState('');
    const [userPass, setUserPass] = useState('');


    const isLoggedIn: boolean = useSelector<any, SessionState["isLoggedIn"]>((state) => state.session.isLoggedIn)
    const dispatch = useDispatch()

    useEffect(() => {
        if(isLoggedIn)
            window.location.href = "/dashboard"
    }, [isLoggedIn])

    const loginButtonPressed = (userId: string, userPassword: string) => {
        dispatch({type: "CREATE_SESSION_INFO", payload: {accountId: userId, accountPassword: userPassword}})
        dispatch(createSessionKey())
    }


  return (
      <div className="row m-0" style={{minHeight: "100vh",padding: "50px", textAlign: 'center', justifyContent: "center"}}>
          <div id={"move-to-own-component-please!"}>
              <div className={"login-box"} style={{marginTop: "10vh"}}>
                  <img src={Logo} style={{width: 250}}/>
                  <p>Login to {BrandName} Dashboard</p>
                      <TextField  style={{width: "80%", fontSize: "2rem", marginBottom: 20}} variant="outlined" required={true} label={"Username"}
                                  full-width onChange={(e) => {setUserId(e.target.value)}}
                      />
                      <TextField  style={{width: "80%", fontSize: "2rem", marginBottom: 20}} variant="outlined" required={true} type="password" label={"Password"}
                                  full-width onChange={(e)=>{setUserPass(e.target.value)}}
                      />
                  <button className={"cav-button"} style={{width: "80%", fontSize: "1rem", marginBottom: 20}} onClick={(e) => {loginButtonPressed(userId,userPass)}}>Login</button>
                  <a href={"/register"}>Create Account</a>
                  <a href={"/forgot"}>Forgot Password?</a>
              </div>
          </div>
      </div>
  );
}

export default Login;
