import {User} from "./userReducer";

export interface RegistrationState {
    user: User | null,
    pass: string | null
}

const initialState : RegistrationState = {
    user: null,
    pass: null
}

type Action = {type: "SET_REG_USER_INFO" | "REG_SUCCESS" , payload: { user: User, pass:string }}

export const registrationReducer = (state:RegistrationState = initialState, action: Action) => {
    switch(action.type) {

        case "SET_REG_USER_INFO":
            return {...state, user: action.payload.user, pass: action.payload.pass}

        case "REG_SUCCESS":
            return {...state}

        default:
            return {...state}
    }
}

export const registerUser = () => async(dispatch: any, getState: any) => {

}






