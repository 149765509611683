import React, {useState} from 'react';
import './AstroCard.scss'
import NickHeadshot from "../../static/images/NickHeadshot.jpg";
import AstroPic from "../../static/images/illustrations/astronaut.svg"
import SandVid from "../../static/videos/sand-vid.mp4";
import {BrandName} from "../../static/data/constants";

function AstroCard() {
    const [isVisible, setVisibility] = useState(false);

    function showHide() {
        setVisibility(!isVisible);
    }
    return (
        <div className={"row"}  style={{
            width: "100%",
            margin: 0,
            minHeight: "40vh",
            backgroundColor: "black",
            justifyContent: 'center',
            padding: 20,
            alignItems: 'center',
            color: "white",
        }}>

            <div className={"col-md-4"} style={{justifyContent: "center"}}>
                <h2 style={{marginBottom: 20}}>Follow {BrandName}'s journey</h2>

                <a href={"https://instagram.com/cavcash"} target="_blank" >
                    <div className={"row"} style={{color: "white", alignItems: "center", margin:5, justifyContent: "center"}}>
                        <svg viewBox={"0 0 100 100"} height={48} >
                            <path className={"social-logo-bw"} d="M 29.996094 9 C 18.417969 9 9 18.425781 9 30.003906 L 9 60.003906 C 9 71.582031 18.425781 81 30.003906 81 L 60.003906 81 C 71.582031 81 81 71.574219 81 59.996094 L 81 29.996094 C 81 18.417969 71.574219 9 59.996094 9 Z M 66 21 C 67.65625 21 69 22.34375 69 24 C 69 25.65625 67.65625 27 66 27 C 64.34375 27 63 25.65625 63 24 C 63 22.34375 64.34375 21 66 21 Z M 45 27 C 54.925781 27 63 35.074219 63 45 C 63 54.925781 54.925781 63 45 63 C 35.074219 63 27 54.925781 27 45 C 27 35.074219 35.074219 27 45 27 Z M 45 33 C 38.371094 33 33 38.371094 33 45 C 33 51.628906 38.371094 57 45 57 C 51.628906 57 57 51.628906 57 45 C 57 38.371094 51.628906 33 45 33 Z M 45 33 "/>
                        </svg>
                        <h5 className={"col-4"}>Instagram</h5>
                    </div>
                </a>

                <a href={"https://twitter.com/cavcash"} target="_blank" >
                    <div className={"row"} style={{color: "white", alignItems: "center", margin: 5, justifyContent: "center"}}>
                        <svg viewBox={"0 0 100 100"} height={48}>
                            <path className={"social-logo-bw"} d="M 84 20.8125 C 81.128906 22.085938 78.046875 22.945312 74.808594 23.332031 C 78.113281 21.351562 80.648438 18.214844 81.84375 14.476562 C 78.753906 16.3125 75.328125 17.644531 71.679688 18.363281 C 68.761719 15.253906 64.601562 13.308594 60 13.308594 C 51.160156 13.308594 43.996094 20.476562 43.996094 29.308594 C 43.996094 30.5625 44.140625 31.789062 44.410156 32.953125 C 31.113281 32.289062 19.324219 25.917969 11.425781 16.234375 C 10.054688 18.597656 9.265625 21.34375 9.265625 24.28125 C 9.265625 29.832031 12.085938 34.730469 16.378906 37.597656 C 13.757812 37.515625 11.289062 36.796875 9.132812 35.597656 C 9.132812 35.667969 9.132812 35.730469 9.132812 35.800781 C 9.132812 43.554688 14.644531 50.023438 21.96875 51.488281 C 20.628906 51.855469 19.210938 52.050781 17.75 52.050781 C 16.722656 52.050781 15.71875 51.949219 14.742188 51.765625 C 16.777344 58.121094 22.6875 62.75 29.691406 62.878906 C 24.214844 67.171875 17.316406 69.730469 9.816406 69.730469 C 8.527344 69.730469 7.25 69.65625 5.996094 69.507812 C 13.078125 74.046875 21.488281 76.695312 30.527344 76.695312 C 59.964844 76.695312 76.054688 52.3125 76.054688 31.164062 C 76.054688 30.472656 76.039062 29.78125 76.011719 29.09375 C 79.140625 26.835938 81.855469 24.019531 84 20.8125 Z M 84 20.8125 "/>
                        </svg>
                        <h5 className={"col-4"}>Twitter</h5>
                    </div>
                </a>

            </div>
            <div className={"col-md-4 d-md-block"} ><img src={AstroPic} style={{height: "35vh"}}/></div>

        </div>
    );
}

export default AstroCard;
