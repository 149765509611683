import React, {useEffect, useState} from 'react';
import './BlogCard.css';
import {News} from "../../reducers/newsroomReducer";
import {User} from "../../reducers/userReducer";
export type BlogCardProps = {
    news: News,
    className?: string
}



function BlogCard(props: BlogCardProps) {


    useEffect(() => {
        getUser()
    }, []);

    const [user, setUser] = useState<User | null>(null);
    async function getUser() {
        if(user === null) {
            fetch(process.env.REACT_APP_API_URL + '/api/User/' + props.news.author, {
                method: 'GET',
                headers: {
                    Authorization: 'MalibuBeach',
                    'Content-type': 'application/json'
                }
            }).then(async (response) => {
                if (response.status !== 200 && response.status !== 201) {
                    throw new Error(response.status + "")
                }
                setUser(await response.json())
            }).catch((e) => {

            });
        }
    }

  return (
      <div style={{ borderRadius: "10px", padding: 0, marginTop: 50, textAlign: 'center'}} className={(props.className || "")+" cav-card blog"} onClick={() => {
          if(props.news.blogID === null)
              return;
          window.location.href = "/blog#blogId="+props.news.blogID
      }}>
          {props.news.title !== null && props.news.content !== null && <div>
              <div style={{backgroundImage: `url("${props.news.coverImage}")`,backgroundPosition: "center center",backgroundSize: "cover",  minHeight: 300, borderTopRightRadius: 12, borderTopLeftRadius: 12, marginTop: 0, marginLeft: -10, marginRight: -10, padding: 10}}>
            </div>
              <h4>{props.news.title}</h4>
              <p className={"font-weight-normal"}>By: {user?.firstName} {user?.lastName}</p>
          </div>}
      </div>
  );
}

export default BlogCard;
