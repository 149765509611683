import React, {useEffect, useState} from 'react';
import './Register.scss';
import {useDispatch, useSelector} from "react-redux";
import {createSessionKey, SessionState} from "../../reducers/sessionReducer";
import {Checkbox, FormControlLabel, TextField} from "@material-ui/core";
import PasswordCreator from "../../components/password-creator/PasswordCreator";
import LargePhotograph from "../../static/images/large-photograph.jpg";
import MomentUtils from "@date-io/moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

function Register() {

    const [page, setPage] = useState<"personal" | "account" | "verify-email" | "bank">("personal");
    const isLoggedIn: boolean = useSelector<any, SessionState["isLoggedIn"]>((state) => state.session.isLoggedIn)
    const dispatch = useDispatch()

    useEffect(() => {
        if (isLoggedIn)
            window.location.href = "/dashboard"

        var hash = window.location.hash.substr(1);
        var items: string[] = hash.split('&');
        items.map((s) => {
            var param: string = s.split('=')[0];
            var val: string = s.split('=')[1];

            switch (param) {
                case "page":
                    if (val === "verify-email")
                        setPage("verify-email");
                    break;
            }
        })


    }, [isLoggedIn])


    // personal information
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [date, setDate] = useState<Date | null>(new Date());
    const [address, setAddress] = useState<any>(null);

    // account information
    const [userId, setUserId] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPass, setUserPass] = useState('');
    const [termsChecked, setTermsChecked] = useState(false);
    const [privacyChecked, setPrivacyChecked] = useState(false);

    // verification information
    const [code, setCode] = useState('')

    function nextPagePersonal() {
        if (firstName === '') {
            dispatch({type: "SHOW_MODAL", payload: {message: "You didn't put a first name!"}})
        } else if (lastName === '') {
            dispatch({type: "SHOW_MODAL", payload: {message: "You didn't put a last name!"}})
        } else if (getAge(date || new Date(), new Date()).years < 13) {
            dispatch({type: "SHOW_MODAL", payload: {message: "You must be 13 or older to use CavCash"}})
        } else if (address === null) {
            dispatch({type: "SHOW_MODAL", payload: {message: "You didn't put an address!"}})
        } else {
            setPage("account")
        }
    }

    function nextPageAccount() {
        if (userEmail === '') {
            dispatch({type: "SHOW_MODAL", payload: {message: "You didn't put a first name!"}})
        } else if (userId === '') {
            dispatch({type: "SHOW_MODAL", payload: {message: "You didn't put a last name!"}})
        } else if (userPass === '') {
            dispatch({type: "SHOW_MODAL", payload: {message: "You must be 13 or older to use CavCash"}})
        } else if (!termsChecked) {
            dispatch({type: "SHOW_MODAL", payload: {message: "You must agree to the CavCash Terms of Service"}})
        } else if (!privacyChecked) {
            dispatch({type: "SHOW_MODAL", payload: {message: "You must agree to the Privacy Policy"}})
        } else {
            const userRequest = {
                firstName: firstName,
                lastName: lastName,
                birthDate: date?.toISOString(),
                homeAddress: {
                    googleLocationId: address.value.place_id
                },
                accountEmail: userEmail,
                accountID: userId,
                accountPassword: userPass,
                charity: "unset",
                captchaToken: "No"
            };

            fetch(process.env.REACT_APP_API_URL + `/api/user/`, {
                method: 'POST',
                body: JSON.stringify(userRequest),
                headers: {
                    Authorization: 'MalibuBeach',
                    'Content-type': 'application/json'
                }
            }).then(async (response) => {
                if (response.status === 201)
                    setPage("verify-email")
                else
                    dispatch({
                        type: "SHOW_MODAL",
                        payload: {message: "We were unable to process your verification.\nError Code: " + (await response.json()).ExceptionCode}
                    })
            });
        }
    }

    function verifyEmail() {
        if (code !== '') {
            fetch(process.env.REACT_APP_API_URL + `/api/user/${userId}/${code}`, {
                method: 'PUT',
                headers: {
                    Authorization: 'MalibuBeach',
                    'Content-type': 'application/json'
                }
            }).then(async (response) => {
                if (response.status == 200) {
                    dispatch({type: "CREATE_SESSION_INFO", payload: {accountId: userId, accountPassword: userPass}})
                    dispatch(createSessionKey())
                } else
                    dispatch({type: "SHOW_MODAL", payload: {message: "We were unable to verify your email."}})
            });
        } else {
            dispatch({type: "SHOW_MODAL", payload: {message: "Please enter an email verification code"}})
        }
    }

    function getAge(birthDate: Date, ageAtDate: Date) {
        var daysInMonth = 30.436875; // Days in a month on average.
        var dob = new Date(birthDate);
        var aad;
        if (!ageAtDate) aad = new Date();
        else aad = new Date(ageAtDate);
        var yearAad = aad.getFullYear();
        var yearDob = dob.getFullYear();
        var years = yearAad - yearDob; // Get age in years.
        dob.setFullYear(yearAad); // Set birthday for this year.
        var aadMillis = aad.getTime();
        var dobMillis = dob.getTime();
        if (aadMillis < dobMillis) {
            --years;
            dob.setFullYear(yearAad - 1); // Set to previous year's birthday
            dobMillis = dob.getTime();
        }
        var days = (aadMillis - dobMillis) / 86400000;
        var monthsDec = days / daysInMonth; // Months with remainder.
        var months = Math.floor(monthsDec); // Remove fraction from month.
        days = Math.floor(daysInMonth * (monthsDec - months));
        return {years: years, months: months, days: days};
    }

    return (
        <div style={{minHeight: "100vh", maxWidth: "100vw", justifyContent: "center", display: "flex"}}>
            <div style={{textAlign: 'center', justifyContent: "center", display: "grid", maxWidth: "90vw"}}>
                <div style={{
                    backgroundImage: `url(${LargePhotograph})`,
                    backgroundSize: "cover",
                    zIndex: -1,
                    width: "100vw",
                    height: "33%",
                    position: "absolute",
                    left: 0
                }}/>

                <div>
                    <h1 style={{fontWeight: 'bold', marginTop: 50, color: "white", fontSize: "4rem"}}>Create an Account</h1>
                    <p style={{color: "white", fontSize: "1rem"}}>We're so excited you've chosen to join us!</p>


                <div style={{justifyContent: "center"}} className={"row row-cols-1"}>
                    <div className={"row w-100"} style={{
                        justifyContent: "space-evenly",
                        backgroundColor: "white",
                        borderRadius: 10,
                        minHeight: 35,
                        maxWidth: 900,
                        padding: 2,
                        alignContent: "center",
                        verticalAlign: "center"
                    }}>
                        <p className={"mb-0 pill-item" + (page === "personal" ? " pill-selected" : "")}
                           style={{height: "min-content"}}>Personal Information</p>
                        <p className={"mb-0 pill-item" + (page === "account" ? " pill-selected" : "")}
                           style={{height: "min-content"}}>Account Information</p>
                        <p className={"mb-0 pill-item" + (page === "verify-email" ? " pill-selected" : "")}
                           style={{height: "min-content"}}>E-Mail Verification</p>
                        <p className={"mb-0 pill-item" + (page === "bank" ? " pill-selected" : "")}
                           style={{height: "min-content"}}>Link a Bank</p>
                    </div>

                    <div
                        className={"cav-card cav-card-a " + (page === "personal" ? "fade-up-cav p-4 m-2" : "fade-down-cav")}>
                        <h2>Personal Information</h2>
                        <p>Enter all information as it appears on your Government Issued ID</p>
                        <TextField style={{width: "100%", fontSize: "2rem", marginBottom: 20}} variant="outlined"
                                   required={true} label={"First Name"}
                                   full-width onChange={(e) => {
                            setFirstName(e.target.value)
                        }} value={firstName}
                        />

                        <TextField style={{width: "100%", fontSize: "2rem", marginBottom: 20}} variant="outlined"
                                   required={true} label={"Last Name"}
                                   full-width onChange={(e) => {
                            setLastName(e.target.value)
                        }} value={lastName}
                        />

                        <p>Address</p>
                        <GooglePlacesAutocomplete
                            selectProps={{
                                value: address,
                                onChange: (e: any) => {
                                    setAddress(e)
                                },
                            }}
                            full-width
                            apiKey="AIzaSyAca1zHyzQo3KUEhOv--7jOsXL7HfwnOD4"
                        />
                        <p className={"font-weight-normal mt-0"}>This is required in order to verify your identity and
                            for our fraud detection software</p>

                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/DD/yyyy"
                                id="date-picker-inline"
                                label="Birthdate"
                                value={date}
                                style={{width: "100%", margin: 0}}
                                onChange={(e: MaterialUiPickersDate) => {
                                    e != null && setDate(new Date(e.toISOString()));
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        <button className={"cav-button"} style={{width: "100%", fontSize: "1rem", marginTop: 15}}
                                onClick={(e) => {
                                    nextPagePersonal()
                                }}>Next
                        </button>
                    </div>

                    <div
                        className={"cav-card cav-card-a " + (page === "account" ? "fade-up-cav p-4 m-2" : "fade-down-cav")}>
                        <h2>Account Information</h2>

                        <TextField style={{width: "100%", fontSize: "2rem", marginBottom: 20}} variant="outlined"
                                   required={true} label={"E-Mail Address"}
                                   full-width onChange={(e) => {
                            setUserEmail(e.target.value)
                        }}
                        />

                        <TextField style={{width: "100%", fontSize: "2rem", marginBottom: 20}} variant="outlined"
                                   required={true} label={"Username"}
                                   full-width onChange={(e) => {
                            setUserId(e.target.value)
                        }}
                        />

                        <PasswordCreator passFunc={setUserPass}/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={termsChecked}
                                    onChange={(e) => {
                                        setTermsChecked(e.target.checked)
                                    }}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="I have read and agree to the CavCash Terms of Service"
                        /> <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={privacyChecked}
                                    onChange={(e) => {
                                        setPrivacyChecked(e.target.checked)
                                    }}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="I have read and agree to the CavCash Privacy Policy"
                        />
                        <button className={"cav-button"} style={{width: "100%", fontSize: "1rem"}} onClick={(e) => {
                            nextPageAccount()
                        }}>Next
                        </button>
                        <button className={"cav-button"} style={{width: "100%", fontSize: "1rem", marginTop: 15}}
                                onClick={(e) => {
                                    setPage("personal")
                                }}>Back
                        </button>

                    </div>

                    <div
                        className={"cav-card cav-card-a " + (page === "verify-email" ? "fade-up-cav p-4 m-2" : "fade-down-cav")}>
                        <TextField style={{width: "100%", fontSize: "2rem", marginBottom: 20}} variant="outlined"
                                   required={true} value={code} label={"E-Mail Code"}
                                   full-width onChange={(e) => {
                            setCode(e.target.value)
                        }}
                        />
                        <button className={"cav-button"} style={{width: "100%", fontSize: "1rem", marginTop: 15}}
                                onClick={(e) => {
                                    verifyEmail()
                                }}>Next
                        </button>

                    </div>
                </div>
                </div>
            </div>
        </div>
    );
}

export default Register;
