import React, {Dispatch, useState} from 'react';

import {Button} from 'react-bootstrap';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Input,
    TextField
} from "@material-ui/core";
import {Transaction} from "../../reducers/transactionReducer";
import NickHeadshot from "../../static/images/NickHeadshot.jpg";
import {User} from "../../reducers/userReducer";
var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
});
function Modal(props: {message: string, isShown: boolean, closefunc:any, callbackFunc: any}) {
        return (
            <div style={{backgroundColor: "rgba(0,0,0,.5)", position: "fixed", left: 0, top: 0, height: "100vh", width: "100%",alignItems: "center", display: props.isShown ? "flex" : "none", zIndex: 420, justifyContent: "center"}}>
                <div className={"text-center"} style={{zIndex: 421, width: "50vh", height: "max-content", backgroundColor: "white", borderRadius: 20,  padding: 10 }}>
                    <h2>Alert</h2>
                      <p style={{fontSize: "1.25rem"}} >{props.message}</p>
                        <a onClick={(e) => {
                            props.closefunc();
                            if(props.callbackFunc != null) {
                                props.callbackFunc();
                            }
                        }} className={"cav-button"}>
                            Close
                        </a>
                </div>
            </div>
        );
}



export default Modal;
