import React, {Dispatch, useState} from 'react';
import './NavBar.css';
import Logo from '../../static/images/CavCashLogoNewNew2021.png';
import {connect, useDispatch, useSelector} from "react-redux";
import {SessionState} from "../../reducers/sessionReducer";
import {User, UserState} from "../../reducers/userReducer";
import Modal from "../modal/Modal";
import {ModalState} from "../../reducers/modalReducer";
import {Navbar, Nav, NavItem, NavDropdown, DropdownButton} from 'react-bootstrap';


function NavBar() {
    const user: User | null = useSelector<any, UserState["user"]>((state) => state.user.user);
    const [menuOpen, setMenuOpen] = useState(false);
    const isLoggedIn: boolean = useSelector<any, SessionState["isLoggedIn"]>((state) => state.session.isLoggedIn)
    const dispatch = useDispatch()
    const modalState: ModalState = useSelector<any, ModalState>((state) => state.modal)

    const logoutUser = () => {
        dispatch({type: "CLEAR_ON_LOGOUT"})
        dispatch({type: "DELETE_SESSION_INFO", payload: null})
    }

    return (
        <div className="NavBar">
            <header>
                <Modal message={modalState.message || ""} isShown={modalState.isOpen} closefunc={() => {
                    dispatch({type: "CLOSE_MODAL"})
                }} callbackFunc={() => {
                    if (modalState.callback != null)
                        modalState.callback()
                }}/>
                <div className={"w-100"}>
                    <Navbar bg="light" expand="lg" className={"row justify-content-center m-0"}>
                        <div className={"row"} style={{width: "80%"}}>
                            <div className="row w-100 d-md-none d-sm-block">
                                <div className={"col-9"}>
                                    <Navbar.Brand href="/">
                                        <img src={Logo} width={150}/>
                                    </Navbar.Brand>
                                </div>
                                <div className={"col-2 ml-3"}>
                                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                                </div>
                            </div>
                            <div className={"d-sm-none d-md-block d-none"}>
                                <Navbar.Brand href="/">
                                    <img src={Logo} width={150}/>
                                </Navbar.Brand>
                            </div>
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="row w-100 ml-5">
                                    <div className="col-md-4 row">
                                        <Nav.Link className="col-sm-12 col-md-3 cav-link" href="/">Home</Nav.Link>
                                        <Nav.Link className="col-sm-12 col-md-3 cav-link" href="/newsroom">Newsroom</Nav.Link>
                                    </div>
                                    <div className="col-md-7"/>
                                    <div className={"col-me-1"}>
                                        {isLoggedIn && <NavDropdown title="Account" id="basic-nav-dropdown">
                                            <NavDropdown.ItemText>{user?.firstName} {user?.lastName}</NavDropdown.ItemText>

                                            <NavDropdown.Divider/>
                                            <NavDropdown.Item href="/dashboard">Dashboard</NavDropdown.Item>
                                            <NavDropdown.Item href="/settings">Settings</NavDropdown.Item>
                                            <NavDropdown.Divider/>
                                            <NavDropdown.Item onClick={(e) => {
                                                logoutUser()
                                            }}>Log Out</NavDropdown.Item>
                                        </NavDropdown>}
                                        {!isLoggedIn && <Nav.Link href="/login">Login</Nav.Link>}
                                    </div>
                                </Nav>
                            </Navbar.Collapse>
                        </div>
                    </Navbar>
                </div>
            </header>
        </div>
    );

}

export default NavBar;
