import React, {useEffect} from 'react';
import './Newsroom.css';
import BlogCard from "../../components/blog-card/BlogCard";
import {useDispatch, useSelector} from "react-redux";
import {getNews, News} from "../../reducers/newsroomReducer";

function Newsroom() {

    const dispatch = useDispatch();
    const newsItems: News[] = useSelector<any,News[]>((state) => state.news.news)
    useEffect(() => {
        dispatch(getNews())
    }, [])


  return (
      <div style={{padding: "50px", textAlign: 'center'}}>

            <h2>Newsroom</h2>
            <div className={"row justify-content-center"}>
                {newsItems != undefined && newsItems.map((e) =>
                <BlogCard news={e} className={"col-md-3"}/>
            )}

                 </div>
      </div>
  );
}

export default Newsroom;
