import React from 'react';
import './Terms.css';
function Terms() {
  return (
    <div style={{padding: 50}}>

        <h2>Terms of Service</h2>
        <hr/>
        <p>
            By agreeing to the “CavCash Terms of Service” (hereon referred to as “this agreement”), you (the End User) are entering a legally binding agreement with CAVCASH INC (hereon referred to as “CavCash” or “the corporation”).
        </p>
        <p>
            If you are a minor, you must have a parent and/or guardian agree to this agreement for you.
        </p>
        <h3><b>Section I - Forbidden Actions</b></h3>
        <p>
            You will not attempt to reverse-engineer, modify, or harm any of the software or services provided by CavCash or its partners
        </p>
        <p>
            Reverse engineering, modifying, or harm refers to, but is not limited to attempting to
            gain unauthorized access to CavCash databases, attempting to login to another user’s account without their permission, attempting to make CavCash services unavailable to users, and attempting to corrupt, delete, or making unauthorized to any data stored by CavCash or its partners.
        </p>
        <p>
            You will not attempt to use any of CavCash's services as any part of a scam or fraudulent activity.
        </p>
    <p>
            You will not harass, verbally abuse, or in any way harm an employee of CavCash. Doing so will result in persecution to the fullest extent of the law, as well as termination of any accounts you have with CavCash. Determining whether actions you have taken qualify as “Harassing, verbally abusing, or in any way harming an CavCash employee” is decided by CavCash, and not by definitions of the law.
        </p>
    <p>
            When specifying your home address in a CavCash service, you must use the address that you are currently in residence of. You may not use the address of a hotel you are temporarily staying at, or a vacation home you are currently renting.
        </p>
    <p>
            When creating an account with CavCash, all of the personal information you provide, which includes but is not limited to your legal first name (also referred to as “your first name”), your legal last name (also referred to as “your last name”), your legal residence (also referred to as “your home address”), your phone number, and your email address.

        </p>

        <h3><b>Section II - Privacy</b></h3>

        <p>
            CavCash discloses the data collected by the CavCash application in their privacy policy (hereon referred to as “the privacy policy”), which can be accessed at the following URL: https://cavcash.com/privacy. By agreeing to this agreement (“CavCash Terms of Service”), you are also confirming that you read and agree to the privacy policy.
        </p> <p>
            Upon deletion or termination of your account, your information collected by CavCash will not be deleted, and will be retained indefinitely. If you would like all of your information to be deleted, you may contact CavCash by email or phone to request that CavCash deletes your data.
        </p>  <p>
        CavCash may decide to share your data with a third-party service in order to provide vital services required to run any of CavCash’s services. These services may include but are not limited to machine learning.

        </p>

        <h3><b>Section III - Software Rights Notice</b></h3>
        <p>
        The underlying source code for this website and all CavCash services as well as the full rights and ownership of that code are the sole property of Nicholas Orlowsky.
        <br/><br/>
        CavCash Inc has a perpetual license to use that code which is revocable at any time. Upon revocation, Nicholas Orlowsky will take over all of CavCash Inc's
        clients and mobile app distributions. This transition should not affect the end-users of CavCash or any of it's associated services in any way.
        </p>

        <h3><b>Section IV - Violations</b></h3>

        <p>
            Violation of this agreement may lead to the deletion or banning of your CavCash account, as well as any other accounts you may have with CavCash.
        </p>

        <p>CAVCASH INC <br/>
       10601 Clarence Drive Suite 250<br/>
        Frisco, Texas 75033<br/><br/>
        Email Address: contact@CavCash.com<br/><br/>
        Telephone number: 5127311307<br/><br/>
        Effective as of August 13, 2021</p>

    </div>
  );
}

export default Terms;
