export interface Address {
    streetAddress: string,
    state: string,
    country: string,
    city: string,
    zip: string,
    googleLocationId: string | null
}

export interface Bank {
    institution: string | null,
    type: string| null
}

export interface User {
    firstName: string,
    lastName: string,
    balance: number,
    birthDate: string,
    homeAddress: Address,
    accountEmail: string,
    phoneNumber: string,
    uuid: string,
    accountID: string,
    accountCreationDate: string
    linkedBank: Bank | null
}

export interface UserState {
    user: User | null
}

const initialState : UserState = {
    user: null
}

type Action = {type: "SET_USER_INFO" | "CLEAR_ON_LOGOUT", payload: { user: User }}

export const userReducer = (state:UserState = initialState, action: Action) => {
    switch(action.type) {

        case "SET_USER_INFO":
            return {...state, user: action.payload.user}

        case "CLEAR_ON_LOGOUT":
            return {}

        default:
            return {...state}
    }
}

export const getUser = () => async(dispatch: any, getState: any) => {
    console.log("getting user...")
    var state = getState().session;

    await fetch(process.env.REACT_APP_API_URL+'/api/User/'+state.accountId+'/'+state.userKey.id, {
        method: 'GET',
        headers: {
            Authorization: 'MalibuBeach',
            'Content-type': 'application/json'
        }
    }).then(async (response) => {
        if(response.status!==200 && response.status!==201)
        {
            throw new Error(response.status+"")
        }
        dispatch({type: "SET_USER_INFO", payload: {user: await response.json()}})
    }).catch((e) => {
        dispatch({type: "SHOW_MODAL", payload: {message: "You were logged out for your security."}})
        dispatch({type: "FAIL_AUTH"})
        dispatch({type: "CLEAR_ON_LOGOUT"})
    });
}
