import React, {useEffect, useState} from 'react';
import './Blog.css';
import {News} from "../../reducers/newsroomReducer";
import ReactMarkdown from 'react-markdown';
import {User} from "../../reducers/userReducer";

function Blog() {
    const [blogId, setBlogId] = useState('');
    const [news, setNews] = useState<News|null>(null);

    useEffect(() => {
        var hash = window.location.hash.substr(1);
        var items: string[] = hash.split('&');
        items.map((s) => {
            var param: string = s.split('=')[0];
            var val: string = s.split('=')[1];

            switch(param) {
                case "blogId":
                    setBlogId(val);
                    break;
            }
        })
    }, [window.location]);

    useEffect(() => {
        if(blogId === null || blogId === "")
            return;
        fetch(process.env.REACT_APP_API_URL+`/api/blog/single/${blogId}?hideImage=false`, {
            method: 'GET',
            headers: {
                Authorization: 'MalibuBeach',
                'Content-type': 'application/json'
            }
        }).then(async (response) => {
            if(response.status !== 200 && response.status !== 201)
                window.location.href = "/404"
            else
                setNews(await response.json())
        });
    }, [blogId]);

    useEffect(() => {
        getUser()
    }, [news]);

    const [user, setUser] = useState<User | null>(null);
    async function getUser() {

        fetch(process.env.REACT_APP_API_URL+'/api/User/'+news?.author, {
            method: 'GET',
            headers: {
                Authorization: 'MalibuBeach',
                'Content-type': 'application/json'
            }
        }).then(async (response) => {
            if(response.status!==200 && response.status!==201)
            {
                throw new Error(response.status+"")
            }
            setUser(await response.json())
        }).catch((e) => {

        });
    }



    return (
        <div style={{minHeight: "100vh"}}>
            {news !== null && user !== null &&
          <div style={{padding: "50px", textAlign: 'center', justifyContent: "center"}} className={"row row-cols-1"}>

              <h1 className={"font-weight-bold"}>{news?.title}</h1>
              <h6>{new Date(news?.createdDate || "").toDateString()} | {user?.firstName} {user?.lastName}</h6>
              <img src={`${news?.coverImage}`} style={{maxWidth: "min(90vw, 900px)"}}/>
              <p style={{fontSize: "1.25rem", maxWidth: 1250, textAlign: "left"}} className={"mt-2"}><ReactMarkdown>{news?.content || ""}</ReactMarkdown></p>
          </div>}
        </div>
  );
}

export default Blog;
