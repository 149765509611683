import React from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
import Home from "./pages/home/Home";
import Footer from "./components/footer/Footer";
import NavBar from "./components/navbar/NavBar";
import NotFound from "./pages/error/not-found/NotFound";
import Attributions from "./pages/attributions/Attributions";
import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import Register from "./pages/register/Register";
import Terms from "./pages/terms/Terms";
import Privacy from "./pages/privacy/Privacy";
import Settings from "./pages/settings/Settings";
import Forgot from "./pages/forgot/Forgot";
import Newsroom from "./pages/newsroom/Newsroom";
import PostBlog from "./pages/post-blog/PostBlog";
import Blog from "./pages/blog/Blog";

function App() {


    return (
        <div id={"App"}>
      <Router>
          <Switch>
              <Route path='/' exact={true}>
                  <NavBarView>
                      <Home/>
                  </NavBarView>
              </Route>
              <Route path='/content-attributions' exact={true}>
                  <NavBarView>
                      <Attributions/>
                  </NavBarView>
              </Route>
              <Route path='/register' exact={true}>
                  <NavBarView>
                      <Register/>
                  </NavBarView>
              </Route>

              <Route path='/dashboard' exact={true}>
                  <NavBarView>
                      <Dashboard/>
                  </NavBarView>
              </Route>

              <Route path='/blog' exact={true}>
                  <NavBarView>
                      <Blog/>
                  </NavBarView>
              </Route>

              <Route path='/login' exact={true}>
                  <NavBarView>
                      <Login/>
                  </NavBarView>
              </Route>

              <Route path='/settings' exact={true}>
                  <NavBarView>
                      <Settings/>
                  </NavBarView>
              </Route>

              <Route path='/admin/blog' exact={true}>
                  <NavBarView>
                      <PostBlog/>
                  </NavBarView>
              </Route>

              <Route path='/forgot' exact={true}>
                  <NavBarView>
                      <Forgot/>
                  </NavBarView>
              </Route>

              <Route path='/newsroom' exact={true}>
                  <NavBarView>
                      <Newsroom/>
                  </NavBarView>
              </Route>

              <Route path='/privacy' exact={true}>
                  <NavBarView>
                      <Privacy/>
                  </NavBarView>
              </Route>

              <Route path='/terms' exact={true}>
                  <NavBarView>
                      <Terms/>
                  </NavBarView>
              </Route>

              <Route path='*'>
                  <NavBarView>
                      <NotFound/>
                  </NavBarView>
              </Route>

          </Switch>
      </Router>
        </div>

  );
}

export function NavBarView(props: any) {
  return(
      <div id="modalRoot">
        <NavBar/>
        <div style={{minHeight: "100vh"}}>
          {props.children}
        </div>
        <Footer />
      </div>
  )
}

export default App;
