export interface ModalState {
    header: string | null,
    message: string | null,
    icon: any | null,
    isOpen: boolean,
    callback: any
}

const initialState : ModalState = {
    header: null,
    message: null,
    icon: null,
    isOpen: false,
    callback: null
}

type Action = {type: "SHOW_MODAL" | "CLOSE_MODAL", payload: { header: string, message: string, icon: any | null, callback: any | null }}

export const modalReducer = (state:ModalState = initialState, action: Action) => {
    switch(action.type) {
        case "SHOW_MODAL":
            return {...state, isOpen: true, message: action.payload.message, callback: action.payload.callback}

        case "CLOSE_MODAL":
            console.log('closed')
            return {...state, isOpen: false}

        default:
            return {...state}
    }
}
