export interface NewBankTransaction {
    toCavCash: boolean,
    amount: number
}

export interface NewBankTransactionState {
    transaction: NewBankTransaction | null,
    status: "none" | "success" | "fail",
    password: string
}

const initialState : NewBankTransactionState = {
    transaction: null,
    status: "none",
    password: ""
}

type Action = {type: "SET_BANK_TRANSACTION" | "FAIL_BANK_TRANSACTION" | "SUCCESS_BANK_TRANSACTION" | "CLEAR_BANK_TRANSACTION_STATUS", payload: {transaction: NewBankTransaction, password: string}}

export const bankTransferReducer = (state:NewBankTransactionState = initialState, action: Action) => {
    switch(action.type) {

        case "SET_BANK_TRANSACTION":
            return {...state, transaction:action.payload.transaction, password: action.payload.password}

        case "FAIL_BANK_TRANSACTION":
            return {...state, status: "fail"}

        case "SUCCESS_BANK_TRANSACTION":
            return {...state, status: "success"}

        case "CLEAR_BANK_TRANSACTION_STATUS":
            return {...state, status: "none"}

        default:
            return {...state}
    }
}

export const makeBankTransaction = () => async(dispatch: any, getState: any) => {
    var sessionState = getState().session;
    var newTransactionState: NewBankTransactionState = getState().bankTransfer;

    await fetch(process.env.REACT_APP_API_URL+'/api/user/'+sessionState.accountId+'/'+newTransactionState.password+'/bank/transfer', {
        method: 'POST',
        body: JSON.stringify(newTransactionState.transaction),
        headers: {
            Authorization: 'MalibuBeach',
            'Content-type': 'application/json'
        }
    }).then(async (response) => {
        if(response.status!==200 && response.status!==201)
        {
            throw new Error(response.status+"")
        }
        dispatch({type: "SUCCESS_BANK_TRANSACTION"})
    }).catch((e) => {
        dispatch({type: "FAIL_BANK_TRANSACTION"})
    });
}

